import {
  SET_DEPARTMENTS,
  SET_DEPARTMENTS_QUERY_PARAMS,
  SET_DEPARTMENTS_SET_IS_FETCHING,
} from '../constants/departmentsActionsTypes';

const initialState = {
  list: [
    { id: 1, name: 'General Orthopedics' },
    { id: 2, name: 'Sports Medicine' },
    { id: 3, name: 'Shoulder & Elbow' },
  ],
  params: {
    per_page: 5,
    page: 1,
    // search: '',
  },
  isFetching: false,
  total: 0,
};

function departments(state = initialState, action) {
  switch (action.type) {
    case SET_DEPARTMENTS:
      return {
        ...state,
        list: action.data.departments,
        params: {
          ...state.params,
          ...action.data.pagination,
        },
        total: action.data.total,
      };
    case SET_DEPARTMENTS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DEPARTMENTS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default departments;
