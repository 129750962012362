import {
  SET_DICTLANGUAGE,
  SET_DICTLANGUAGES,
  SET_DICTLANGUAGES_SET_IS_FETCHING,
  SET_DICTLANGUAGES_QUERY_PARAMS,
} from '../constants/languagesActionsTypes';

const initialState = {
  list: [],
  params: {
    page: 1,
    perPage: 10,
    sort: 'created_at,desc',
    search: '',
  },
  dictLanguage: {},
  isFetching: false,
  total: 0,
};

function dictLanguages(state = initialState, action) {
  switch (action.type) {
    case SET_DICTLANGUAGE:
      return {
        ...state,
        dictLanguage: action.data,
      };
    case SET_DICTLANGUAGES:
      return {
        ...state,
        list: action.data.dictLanguages,
        total: action.data.total,
      };
    case SET_DICTLANGUAGES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTLANGUAGES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictLanguages;
