import { SET_BODYPART, SET_BODYPARTS_SET_IS_FETCHING } from '../constants/bodyPartsActionsTypes';

const initialState = {
  bodyPart: {},
  isFetching: false,
};

function bodyPart(state = initialState, action) {
  switch (action.type) {
    case SET_BODYPART:
      return {
        ...state,
        bodyPart: action.data,
      };
    case SET_BODYPARTS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default bodyPart;
