import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tag, Icon } from 'antd';
import invoke from 'lodash/invoke';
import { withTranslation } from 'react-i18next';

import frontendApps from '../../config/frontendApps';

import './ServerError.less';

class ServerError extends Component {
  render() {
    return (
      <div className={classNames('server-error-root', this.props.rootClassName)}>
        <div className="left-side">
          <div className="shapes">
            <div className="shape-1" />
            <div className="shape-2" />
            <div className="shape-3" />
            <div className="shape-4" />
            <div className="shape-5" />
          </div>
          <div className="content">
            <div className="main-part">
              <div className="label">
                <span>ADMIN</span>
                <span>PORTAL</span>
              </div>
              <div className="message">
                <div className="title">{invoke(this.props, 't', 'Oops!')}</div>
                <div className="text">{invoke(this.props, 't', '500 internal server error')}</div>
                <div className="error">
                  <span>{invoke(this.props, 't', 'Error code')}: </span>
                  <span>500</span>
                </div>
              </div>
              <Tag className="server-error-button">
                <a href={frontendApps.auth}>{invoke(this.props, 't', 'Go Back')}</a>
                <Icon type="right" />
              </Tag>
            </div>
          </div>
        </div>
        <div className="right-side" />
      </div>
    );
  }
}

ServerError.propTypes = {
  rootClassName: PropTypes.string,
};

ServerError.defaultProps = {
  rootClassName: '',
};

export default withTranslation()(ServerError);
