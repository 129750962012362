const REDUCER_PREFIX = 'CALL_CENTER-';

export const UPDATE_DOCTORS_FILTERS_PARAMS = `${REDUCER_PREFIX}UPDATE_DOCTORS_FILTERS_PARAMS`;
export const UPDATE_DOCTORS_SORT_PARAMS = `${REDUCER_PREFIX}UPDATE_DOCTORS_SORT_PARAMS`;
export const LOAD_DOCTORS_START = `${REDUCER_PREFIX}LOAD_DOCTORS_START`;
export const LOAD_DOCTORS_SUCCESS = `${REDUCER_PREFIX}LOAD_DOCTORS_SUCCESS`;
export const LOAD_DOCTORS_FAILURE = `${REDUCER_PREFIX}LOAD_DOCTORS_FAILURE`;
export const CLEAR_DOCTORS_FILTER = `${REDUCER_PREFIX}CLEAR_DOCTORS_FILTER`;
export const CLEAR_DOCTORS_STORE = `${REDUCER_PREFIX}CLEAR_DOCTORS_STORE`;

export const UPDATE_LOCATIONS_FILTERS_PARAMS = `${REDUCER_PREFIX}UPDATE_LOCATIONS_FILTERS_PARAMS`;
export const LOAD_LOCATIONS_START = `${REDUCER_PREFIX}LOAD_LOCATIONS_START`;
export const LOAD_LOCATIONS_SUCCESS = `${REDUCER_PREFIX}LOAD_LOCATIONS_SUCCESS`;
export const LOAD_LOCATIONS_FAILURE = `${REDUCER_PREFIX}LOAD_LOCATIONS_FAILURE`;
export const CLEAR_LOCATIONS_FILTERS = `${REDUCER_PREFIX}CLEAR_LOCATIONS_FILTERS`;
export const CLEAR_LOCATIONS_STORE = `${REDUCER_PREFIX}CLEAR_LOCATIONS_STORE`;

export const CLEAR_ALL_CALL_CALL_CENTER_STORE = `${REDUCER_PREFIX}CLEAR_ALL_CALL_CALL_CENTER_STORE`;
