import * as actionTypes from '../constants/callCenterActionTypes';

const initialState = {
  doctors: {
    filters: {
      per_page: 5,
      page: 1,
    },
    sort: {
      sort: 'last_name',
    },
    doctorsList: [],
    total: 0,
    isLoading: false,
    message: null,
    orderOfDoctors: [],
  },
  locations: {
    filters: {},
    locationsList: [],
    isLoading: false,
    message: null,
  },
};

function callCenter(state = initialState, action) {
  switch (action.type) {
    // DOCTORS
    case actionTypes.UPDATE_DOCTORS_FILTERS_PARAMS:
      return {
        doctors: {
          ...state.doctors,
          filters: {
            ...state.doctors.filters,
            ...action.payload,
          },
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.UPDATE_DOCTORS_SORT_PARAMS:
      return {
        doctors: {
          ...state.doctors,
          sort: {
            ...action.payload,
          },
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.LOAD_DOCTORS_START:
      return {
        doctors: {
          ...state.doctors,
          isLoading: true,
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.LOAD_DOCTORS_SUCCESS:
      return {
        doctors: {
          ...state.doctors,
          isLoading: false,
          doctorsList: action.payload,
          total: action.payload.count,
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.LOAD_DOCTORS_FAILURE:
      return {
        doctors: {
          ...state.doctors,
          isLoading: false,
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.CLEAR_DOCTORS_FILTER:
      return {
        doctors: {
          filters: {
            ...{},
            page: state.doctors.filters.page,
            per_page: state.doctors.filters.per_page,
          },
          sort: {
            ...state.doctors.sort,
          },
          ...state.doctors.doctorsList,
          ...state.doctors.isLoading,
          ...state.doctors.message,
          ...state.doctors.total,
        },
        locations: {
          ...state.locations,
        },
      };
    case actionTypes.CLEAR_DOCTORS_STORE:
      return {
        doctors: {
          ...initialState.doctors,
        },
        locations: {
          ...state.locations,
        },
      };
    // LOCATIONS
    case actionTypes.UPDATE_LOCATIONS_FILTERS_PARAMS:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...state.locations,
          filters: {
            ...action.payload,
          },
        },
      };
    case actionTypes.LOAD_LOCATIONS_START:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...state.locations,
          isLoading: true,
        },
      };
    case actionTypes.LOAD_LOCATIONS_SUCCESS:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...state.locations,
          isLoading: false,
          locationsList: {
            ...action.payload,
          },
        },
      };
    case actionTypes.LOAD_LOCATIONS_FAILURE:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...state.locations,
          isLoading: false,
        },
      };
    case actionTypes.CLEAR_LOCATIONS_FILTERS:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...state.locations,
          filters: {},
        },
      };
    case actionTypes.CLEAR_LOCATIONS_STORE:
      return {
        doctors: {
          ...state.doctors,
        },
        locations: {
          ...initialState.locations,
        },
      };
    // MAIN
    case actionTypes.CLEAR_ALL_CALL_CALL_CENTER_STORE:
      return initialState;
    default:
      return state;
  }
}

export default callCenter;
