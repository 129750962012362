/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import axios from 'axios';

import api from '../config/api';
import frontendApps from '../config/frontendApps';
import refreshRequestStore from './refreshRequest';

class StsDAO {
  constructor() {
    this.client = axios.create({
      baseURL: api.ms.sts,
    });

    this.public = {
      logout: () => this.client.get('/logout'),

      appLogout: () => {
        console.info(`Exit from the app`);
        return this.public.logout().finally(() => {
          window.location.replace(`${frontendApps.auth}/login?redirect=${window.location.href}`);
        });
      },

      appAccount: () => {
        console.info(`Go to Account`);
        window.location.replace(`${frontendApps.auth}/account`);
      },

      loginAsUser: (userId, options) => {
        // put redirect: 'no_redirect' if you don't want to redirect automatically
        if (!userId) return Promise.reject('userId is required');
        const redirectParam = options && options.redirect ? `?redirect=${options.redirect}` : '';

        return this.client.post(`/login/as-user${redirectParam}`, {
          user_id: userId,
        });
      },

      goToUsers: () => {
        console.info(`Go to PG Users`);
        window.location.replace(`${frontendApps.portal}/users`);
      },

      backToOrigin: () => this.client.get('/login/back-to-origin'),

      logoutAll: ({ ...options }) => this.client.get('/logout-all', { ...options }).then(r => r),

      refresh: () => this.client.get('/refresh'),

      refreshToken: async () => {
        try {
          await this.public.refresh();
          refreshRequestStore.setRefreshRequest(null);
        } catch (e) {
          // logout from app if refresh request failed
          this.public.appLogout();
          console.error(e);
          throw e;
        }
      },
    };
  }
}

const DAO = new StsDAO();

export const Sts = DAO.public;

export default WrappedComponent =>
  class StsHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} stsDAO={Sts} />;
    }
  };
