import * as userTypes from '../constants/userActionsTypes';
import { Users } from '../../DAO_hocs/usersDAO_HOC';
import { showErrorToast, showLoader, hideLoader } from './meta.action';
import { Sts } from '../../DAO_hocs/stsDAO_HOC';
import {logoutFromGoogle} from "../controllers/account.controller";

const setUsersList = data => ({
  type: userTypes.FETCH_USERS_LIST,
  data,
});

const setSingleUser = user => ({
  type: userTypes.GET_SINGLE_USER,
  data: user.data,
});

const setUserParams = params => ({
  type: userTypes.SET_USERS_PARAMS,
  params,
});

const clearUsersParams = () => ({
  type: userTypes.CLEAR_USERS_PARAMS,
});

const clearUsersStore = () => ({
  type: userTypes.CLEAR_USERS_STORE,
});

const clearSingleUser = () => ({
  type: userTypes.CLEAR_SINGLE_USER_STORE,
});

/** ********************
 * Action Controllers *
 ******************* */

export const setAllUsers = options => (dispatch, getState) => {
  const { params } = getState().user;
  const apiParams = { ...params, ...options.params };
  dispatch(showLoader());

  return Users.getUsers({ params: apiParams })
    .then(response => {
      const { users, page, per_page: perPage, count: total } = response.data.data;
      dispatch(
        setUsersList({
          users,
          params: {
            page,
            perPage,
            search: apiParams.search,
            sort: apiParams.sort,
            scope: apiParams.scope,
          },
          total,
        }),
      );
      dispatch(hideLoader());
    })
    .catch(error => {
      console.log(error); //eslint-disable-line
      dispatch(hideLoader());
      dispatch(showErrorToast({ message: error.message }));
    });
};

export const getSingleUser = id => dispatch =>
  Users.getUserById(id)
    .then(user => dispatch(setSingleUser(user.data)))
    .catch(error => {
        console.log(error); //eslint-disable-line
      dispatch(hideLoader());
      dispatch(showErrorToast({ message: error.message }));
    });

export const deleteUser = id => (dispatch, getState) => {
  const { params } = getState().user;
  dispatch(showLoader());
  Users.deleteUser(id)
    .then(() => {
      dispatch(setAllUsers(params));
      dispatch(hideLoader());
    })
    .catch(error => {
      console.error(error);
      dispatch(hideLoader());
      dispatch(showErrorToast({ message: error.message }));
    });
};

export const createUser = user => (dispatch, getState) =>
  Users.createUser(user)
    .then(() => {
      const { params } = getState().user;
      dispatch(setAllUsers(params));
    })
    .catch(error => {
      console.error(error);
      dispatch(showErrorToast({ message: error.message }));
    });

export const setParams = (newParams, method) => (dispatch, getState) => {
  const { params: reduxParams } = getState().user;
  const params = { ...reduxParams, ...newParams };
  dispatch(showLoader());
  dispatch(setUserParams(params));
  dispatch(hideLoader());
  if (method.get) dispatch(setAllUsers(params));
};

export const clearParams = () => dispatch => dispatch(clearUsersParams());

export const clearStore = () => dispatch => dispatch(clearUsersStore());

export const clearSingleUserStore = () => dispatch => dispatch(clearSingleUser());

export const updateUser = (id, values) => (dispatch, getState) => {
  const { singleUser } = getState().user;
  Users.updateUser({ id, ...values })
    .then(() => {
      dispatch(getSingleUser({ id: singleUser.id }));
    })
    .catch(error => {
      console.error(error);
      dispatch(showErrorToast({ message: error.message }));
    });
};

export const restoreUser = id => (dispatch, getState) => {
  const { params } = getState().user;
  dispatch(showLoader());
  Users.restoreUser(id)
    .then(() => {
      dispatch(setAllUsers(params));
      dispatch(hideLoader());
    })
    .catch(error => {
      console.error(error);
      dispatch(hideLoader());
      dispatch(showErrorToast({ message: error.message }));
    });
};

export const goToAccount = () => async dispatch => {
  try {
    dispatch(setSingleUser({}));
    await Sts.appAccount();
      logoutFromGoogle();
  } catch (e) {
    console.error(e);
  }
};
