import { SET_CONDITION, SET_CONDITIONS_SET_IS_FETCHING } from '../constants/conditionsActionsTypes';

const initialState = {
  condition: {},
  isFetching: false,
};

function condition(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITION:
      return {
        ...state,
        condition: action.data,
      };
    case SET_CONDITIONS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default condition;
