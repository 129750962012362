import {
  SET_DICTDEPARTMENT,
  SET_DICTDEPARTMENTS,
  SET_DICTDEPARTMENTS_SET_IS_FETCHING,
  SET_DICTDEPARTMENTS_QUERY_PARAMS,
} from '../constants/departmentsActionsTypes';

const initialState = {
  list: [],
  params: {
    search: '',
  },
  dictDepartment: {},
  isFetching: false,
  total: 0,
};

function dictDepartments(state = initialState, action) {
  switch (action.type) {
    case SET_DICTDEPARTMENT:
      return {
        ...state,
        dictDepartment: action.data,
      };
    case SET_DICTDEPARTMENTS:
      return {
        ...state,
        list: action.data.dictDepartments,
        total: action.data.total,
      };
    case SET_DICTDEPARTMENTS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTDEPARTMENTS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictDepartments;
