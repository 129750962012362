// const constantedPermissions = [
//   'permissions',
//   'roles',
//   'users',
//   'clients',
//   'licenses',
//   'companies',
//   'clientsProfiles',
//   'marketingProfiles',
//   'campaigns',
//   'locations',
//   'productLines',
//   'marketingServices',
//   'conversions',
//   'webTraffics',
//   'adVariables',
//   'marketingPlans'];

export const PERMISSIONS = {
  READ: 'permissions:read',
  CREATE: 'permissions:create',
  UPDATE: 'permissions:update',
  DELETE: 'permissions:remove',
};

export const ROLES = {
  READ: 'roles:read',
  CREATE: 'roles:create',
  UPDATE: 'roles:update',
  DELETE: 'roles:remove',
};

export const USERS = {
  READ: 'users:read',
  CREATE: 'users:create',
  UPDATE: 'users:update',
  DELETE: 'users:remove',
};

export const LICENSES = {
  READ: 'licenses:read',
  CREATE: 'licenses:create',
  UPDATE: 'licenses:update',
  DELETE: 'licenses:remove',
};

export const DOCTORS = {
  READ: 'doctors:read',
  CREATE: 'doctors:create',
  UPDATE: 'doctors:update',
  DELETE: 'doctors:remove',
};

export const APPROVALS = {
  READ: 'approvals:read',
  UPDATE: 'approvals:update',
};

export const INSURANCES = {
  READ: 'insurances:read',
  CREATE: 'insurances:create',
  UPDATE: 'insurances:update',
  DELETE: 'insurances:remove',
};

export const INSURANCE_COMPANIES = {
  READ: 'insuranceCompanies:read',
  CREATE: 'insuranceCompanies:create',
  UPDATE: 'insuranceCompanies:update',
  DELETE: 'insuranceCompanies:remove',
};

export const DEPARTMENTS = {
  READ: 'departments:read',
  CREATE: 'departments:create',
  UPDATE: 'departments:update',
  DELETE: 'departments:remove',
};

export const SUBSPECIALTIES = {
  READ: 'subSpecialties:read',
  CREATE: 'subSpecialties:create',
  UPDATE: 'subSpecialties:update',
  DELETE: 'subSpecialties:remove',
};

export const HOSPITAL_AFFILIATIONS = {
  READ: 'hospitalAffiliations:read',
  CREATE: 'hospitalAffiliations:create',
  UPDATE: 'hospitalAffiliations:update',
  DELETE: 'hospitalAffiliations:remove',
};

export const HOSPITAL_SYSTEMS = {
  READ: 'hospitalSystems:read',
  CREATE: 'hospitalSystems:create',
  UPDATE: 'hospitalSystems:update',
  DELETE: 'hospitalSystems:remove',
};

export const LOCATIONS = {
  READ: 'locations:read',
  CREATE: 'locations:create',
  UPDATE: 'locations:update',
  DELETE: 'locations:remove',
};

export const BODY_PARTS = {
  READ: 'bodyParts:read',
  CREATE: 'bodyParts:create',
  UPDATE: 'bodyParts:update',
  DELETE: 'bodyParts:remove',
};

export const CONDITIONS = {
  READ: 'conditions:read',
  CREATE: 'conditions:create',
  UPDATE: 'conditions:update',
  DELETE: 'conditions:remove',
};

export const PROCEDURES = {
  READ: 'procedures:read',
  CREATE: 'procedures:create',
  UPDATE: 'procedures:update',
  DELETE: 'procedures:remove',
};

export const PRACTICES = {
  READ: 'practices:read',
  CREATE: 'practices:create',
  UPDATE: 'practices:update',
  DELETE: 'practices:remove',
};

export const DICTIONARIES = {
  READ: 'dictionaries:read',
  CREATE: 'dictionaries:create',
  UPDATE: 'dictionaries:update',
  DELETE: 'dictionaries:remove',
};

export const GMB = {
  READ: 'gmb:read',
  CREATE: 'gmb:create',
  UPDATE: 'gmb:update',
  DELETE: 'gmb:remove',
};

export const roles = {
  admin: 'admin',
  clientAdmin: 'client_admin',
  customerAdminPlus: 'customer-admin+',
  callCenter: 'call_center',
  doctor: 'doctor',
}
