import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import find from "lodash/find";

import { loadAccount, clearAccount } from './redux/controllers/account.controller';
import Layout from './components/Layout/Layout';
import PageSpinner from './components/Spinner/PageSpinner';
import ForbiddenPage from './pages/Forbidden/Forbidden';
import ServerErrorPage from './pages/ServerError/ServerError';
import GAuth from './components/GAuth';
import { licenseValidation } from './redux/actions/meta.action';
import routes from './pages';
import './theme.less';
import './App.less';

@connect(
  store => ({
    account: store.account.account,
    isLoad: store.account.isLoad,
    isError: store.account.isError,
    appForbidden: store.meta.appForbidden,
    internalServerError: store.meta.internalServerError,
    userLicenseType: store.license.userLicenseType,
  }),
  {
    loadAccount,
    clearAccount,
    licenseValidation,
  },
)
class App extends Component {
  componentDidMount() {
    this.props.licenseValidation();
    this.props.loadAccount();
  }
  componentWillUnmount() {
    this.props.clearAccount();
  }

  isValidPermission = (allowed, permissions) => find(permissions, ['title', allowed]);

  render() {
    const { isLoad, isError, appForbidden, internalServerError, account, userLicenseType } = this.props;
    if (!isLoad) {
      return <PageSpinner msg="Loading..." />;
    }

    if (appForbidden && window.location.pathname === '/') return <ForbiddenPage />;
    if (isError || internalServerError) return <ServerErrorPage />;

    return (
      <BrowserRouter>
        <Switch>
          <Layout isAdmin={account.role_id === 1}>
            <Suspense fallback={<PageSpinner />}>
              <Switch>
                {routes.map(route => (
                  <Route
                    key={`${route.path}-tt`}
                    staticContext={this.props.context} // eslint-disable-line
                    {...this.props}
                    {...route}
                  />
                ))}
              </Switch>
            </Suspense>
            {userLicenseType && userLicenseType.restrictions &&
            userLicenseType.restrictions.GMB && <GAuth />}
          </Layout>
        </Switch>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  loadAccount: propTypes.func,
  clearAccount: propTypes.func,
  isLoad: propTypes.bool,
  isError: propTypes.bool,
  account: propTypes.objectOf(propTypes.string),
  appForbidden: propTypes.bool,
  internalServerError: propTypes.bool,
  licenseValidation: propTypes.func,
  userLicenseType: propTypes.shape({
    restrictions: propTypes.shape({
      GMB: propTypes.bool,
    }),
  }),
};

App.defaultProps = {
  loadAccount: null,
  clearAccount: null,
  isLoad: false,
  isError: false,
  account: null,
  appForbidden: false,
  internalServerError: false,
  licenseValidation: null,
  userLicenseType: null,
};

export default App;
