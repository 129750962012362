import { Licenses } from "../../DAO_hocs/licensesDAO_HOC";
import * as licenseTypes from '../constants/licenseActionsTypes';

const setSingleLicense = license => ({
  type: licenseTypes.FETCH_SINGLE_LICENSE,
  data:  license,
});

const loadLicenseFailure = error => ({
  type: licenseTypes.FETCH_LICENSE_FAIL,
  error,
});

const getLicense = () => async (dispatch, getState) => {
  const state = await getState();
  const id = state.account && state.account.account && state.account.account.tenant_id;

  try {
      const response = await Licenses.getLicensesByTenantId({ id, params: {withModule: true} });
      const restrictions = response.data.data && response.data.data[0] && response.data.data[0].amount;
      console.info('restrictions', restrictions);
      if (!restrictions || restrictions.gmb === undefined) {
          return;
      }

      await dispatch(setSingleLicense({restrictions : {GMB: true}}));
    } catch (err) {
      console.error('error', err);
      await dispatch(loadLicenseFailure(true))
      // await dispatch(setSingleLicense({restrictions : {GMB: true}}));
    }
}

export default getLicense;
