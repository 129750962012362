import {
    FETCH_LISTING_START,
    FETCH_LISTING_SUCCESS,
    FETCH_LISTING_FAILURE,

    LOAD_LISTINGS_SUMMARY,
    LOAD_LISTINGS_SUCCESS,
    LOAD_LISTINGS_START,
    LOAD_LISTINGS_FAILURE,
    SET_LISTINGS_QUERY_PARAMS,
    SET_LISTINGS_SET_IS_FETCHING,

    SYNC_LISTING_GMB,
    SYNC_LISTING_GMB_IN_PROGRESS,
} from '../constants/listingsActionTypes';


export const loadListingStart = () => ({
  type: FETCH_LISTING_START,
});

export const loadListingSuccess = listing => ({
  type: FETCH_LISTING_SUCCESS,
  payload: listing,
});

export const loadListingFailure = err => ({
  type: FETCH_LISTING_FAILURE,
  payload: err,
});

export function setListingsSummary(data) {
    return {
        type: LOAD_LISTINGS_SUMMARY,
        data,
    };
}

export function setListings(data) {
  return {
    type: LOAD_LISTINGS_SUCCESS,
    data,
  };
}

export const loadListingsStart = () => ({
    type: LOAD_LISTINGS_START,
});

export const loadListingsFailure = () => ({
    type: LOAD_LISTINGS_FAILURE,
});

export function syncListingGMB(data) {
    return {
        type: SYNC_LISTING_GMB,
        data,
    };
}

export function setQueryParams(params) {
  return {
    type: SET_LISTINGS_QUERY_PARAMS,
    params,
  };
}

export function setIsFetching(isFetching) {
  return {
    type: SET_LISTINGS_SET_IS_FETCHING,
    isFetching,
  };
}

export function setListingIsLoading(isSyncing) {
    return {
        type: SYNC_LISTING_GMB_IN_PROGRESS,
        isSyncing,
    };
}

