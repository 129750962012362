import {
  SET_CONDITIONS,
  SET_CONDITIONS_QUERY_PARAMS,
  SET_CONDITIONS_SET_IS_FETCHING,
} from '../constants/conditionsActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 10,
    page: 1,
    sort: 'created_at,desc',
    search: '',
  },
  isFetching: false,
  total: 0,
};

function conditions(state = initialState, action) {
  switch (action.type) {
    case SET_CONDITIONS:
      return {
        ...state,
        list: action.data.conditions,
        total: action.data.total,
      };
    case SET_CONDITIONS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_CONDITIONS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default conditions;
