import {
  SET_DEPARTMENT,
  SET_DEPARTMENTS_SET_IS_FETCHING,
} from '../constants/departmentsActionsTypes';

const initialState = {
  department: {},
  isFetching: false,
};

function department(state = initialState, action) {
  switch (action.type) {
    case SET_DEPARTMENT:
      return {
        ...state,
        department: action.data,
      };
    case SET_DEPARTMENTS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default department;
