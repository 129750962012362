import { SET_PROCEDURE, SET_PROCEDURES_SET_IS_FETCHING } from '../constants/proceduresActionsTypes';

const initialState = {
  procedure: {},
  isFetching: false,
};

function procedure(state = initialState, action) {
  switch (action.type) {
    case SET_PROCEDURE:
      return {
        ...state,
        procedure: action.data,
      };
    case SET_PROCEDURES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default procedure;
