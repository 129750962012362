import {
  SET_DICTPROCEDURE,
  SET_DICTPROCEDURES,
  SET_DICTPROCEDURES_QUERY_PARAMS,
  SET_DICTPROCEDURES_SET_IS_FETCHING,
} from '../constants/proceduresActionsTypes';

const initialState = {
  list: [],
  params: {
    search: '',
  },
  dictProcedure: {},
  isFetching: false,
  total: 0,
};

function dictProcedures(state = initialState, action) {
  switch (action.type) {
    case SET_DICTPROCEDURE:
      return {
        ...state,
        dictProcedure: action.data,
      };
    case SET_DICTPROCEDURES:
      return {
        ...state,
        list: action.data.dictProcedures,
        params: {
          ...state.params,
          ...action.data.params,
        },
        total: action.data.total,
      };
    case SET_DICTPROCEDURES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTPROCEDURES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictProcedures;
