import {
  SET_DICTCREDENTIAL,
  SET_DICTCREDENTIALS,
  SET_DICTCREDENTIALS_SET_IS_FETCHING,
  SET_DICTCREDENTIALS_QUERY_PARAMS,
} from '../constants/credentialsActionsTypes';

const initialState = {
  list: [],
  params: {
    page: 1,
    perPage: 10,
    sort: 'created_at,desc',
    search: '',
  },
  dictCredential: {},
  isFetching: false,
  total: 0,
};

function dictCredentials(state = initialState, action) {
  switch (action.type) {
    case SET_DICTCREDENTIAL:
      return {
        ...state,
        dictCredential: action.data,
      };
    case SET_DICTCREDENTIALS:
      return {
        ...state,
        list: action.data.dictCredentials,
        total: action.data.total,
      };
    case SET_DICTCREDENTIALS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTCREDENTIALS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictCredentials;
