import classNames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import includes from 'lodash/includes';
import invoke from 'lodash/invoke';
import isEmpty from 'lodash/isEmpty';
import property from 'lodash/property';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Layout, Icon, Dropdown, Menu, Button } from 'antd';
import { withTranslation } from 'react-i18next';

import Avatar from '../Avatar/Avatar';
import BackToOriginUser from '../BackToOriginUser';
import { MenuTriggerIcon, ProfileCloseIcon, DoorIcon } from '../Icons';

import { logout } from '../../redux/controllers/account.controller';
import { goToAccount } from '../../redux/actions/user.action';

const ONLY_CLICK = ['click'];
@connect(
  store => ({
    account: get(store, 'account.account'),
  }),
  {
    logout,
    goToAccount,
  },
)
class HeaderComponent extends Component {
  getDropdownMenu = () => {
    const handleGoToAccount = this.props.goToAccount;
    const handleLogout = this.props.logout;
    const t = this.props.t || identity;
    const language = get(this.props, 'i18n.language', 'en-US');
    return (
      <Menu>
        <Menu.Item>
          <Icon className="icon dropdown-icon" component={ProfileCloseIcon} />
          <Button htmlType="button" className="btn-default" onClick={handleGoToAccount}>
            {t('Personal Data')}
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Icon className="icon dropdown-icon" component={DoorIcon} />
          <Button htmlType="button" className="btn-default" onClick={handleLogout}>
            {t('Log Out')}
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            htmlType="button"
            className={classNames('btn-default', { inactive: language === 'en-US' })}
            onClick={this.setLanguageEnglish}
          >
            English
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button
            htmlType="button"
            className={classNames('btn-default', { inactive: language === 'de-DE' })}
            onClick={this.setLanguageGerman}
          >
            Deutsch
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  setLanguageEnglish = () => invoke(this.props, 'i18n.changeLanguage', 'en-US');
  setLanguageGerman = () => invoke(this.props, 'i18n.changeLanguage', 'de-DE');
  render() {
    const account = get(this.props, 'account');
    if (isEmpty(account)) return null;
    const { toggle } = this.props;
    const hasAvatar = !isEmpty(get(account, 'files'));
    const firstName = get(account, 'first_name');
    const lastName = get(account, 'last_name');
    const fullName = `${firstName} ${lastName}`;
    const roleTitle = get(account, 'role.title');
    const id = get(account, 'id');

    return (
      <Layout.Header className="header">
        <div className="header-first-section">
          {includes(['admin', 'client_admin'], roleTitle) && (
            <Icon className="icon trigger" component={MenuTriggerIcon} onClick={toggle} />
          )}
          <div className="company-bg-main">
            <div className="company-logo">
              <Link className="company-logo-link" to="/">
                <span>WebSitter</span>
                <span>Pro</span>
              </Link>
            </div>
          </div>
          <div className="shapes">
            <div className="shape-1" />
          </div>
        </div>
        <div className="ant-row-flex ant-row-flex-middle ant-row-flex-center" style={{paddingRight: "10px"}}>
          <div id="google-signin-btn" className="g-signin2" />
        </div>
        <div className="header-second-section ant-row-flex ant-row-flex-middle ant-row-flex-center">
          <Dropdown
            overlay={this.getDropdownMenu()}
            getPopupContainer={property(
              'parentNode',
            )} /* tie to parentNode, default tie to document.body */
            trigger={ONLY_CLICK}
            placement="bottomRight"
            overlayClassName="header-dropdown"
          >
            <div className="hover-pointer">
              {hasAvatar ? (
                <Avatar username={fullName} id={id} className="profile-avatar" />
              ) : (
                <Avatar
                  username={fullName}
                  id={id}
                  className="profile-avatar"
                  src=""
                  useBasePath={false}
                />
              )}
              <span className="header__user-name">{fullName}</span>
            </div>
          </Dropdown>
        </div>
        <div className="header-last-section ant-row-flex ant-row-flex-middle ant-row-flex-center">
          <BackToOriginUser />
        </div>
      </Layout.Header>
    );
  }
}

HeaderComponent.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    role: PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
    files: PropTypes.array,
  }),
  toggle: PropTypes.func.isRequired,
  logout: PropTypes.func,
  goToAccount: PropTypes.func,
  t: PropTypes.func,
};

HeaderComponent.defaultProps = {
  account: null,
  logout: null,
  goToAccount: null,
  t: identity,
};

export default withTranslation()(HeaderComponent);
