import {
  SET_LOCATIONS,
  SET_LOCATIONSMODAL,
  SET_LOCATIONS_QUERY_PARAMS,
  SET_LOCATIONS_SET_IS_FETCHING,
} from '../constants/locationsActionsTypes';

const initialState = {
  list: [],
  listModal: [],
  params: {
    per_page: 5,
    page: 1,
    search: '',
  },
  isFetching: false,
  total: 0,
};

function locations(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATIONS:
      return {
        ...state,
        list: action.data.locations,
        params: {
          ...state.params,
          ...action.data.pagination,
        },
        total: action.data.total,
      };
    case SET_LOCATIONSMODAL:
      return {
        ...state,
        listModal: action.data.locations,
      };
    case SET_LOCATIONS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_LOCATIONS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default locations;
