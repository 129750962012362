import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
  DashboardIcon,
  NotificationIcon,
  PracticesIcon,
  ProfilesIcon,
  LocationIcon,
  DepartmentsIcon,
  InsurancesIcon,
  HospitalAffiliationsIcon,
  MenuDotsIcon,
  OrganisationsIcon,
  WorldIcon,
} from '../Icons';
import routes from '../../pages';
import NavModuleList from '../NavModuleList';

@withRouter
@connect(store => ({
  account: store.account.account,
  userLicenseType: store.license.userLicenseType,
}))
class MenuComponent extends Component {
  static getIconByRouteTitle(title) {
    switch (title) {
      case 'Dashboard':
        return DashboardIcon;
      case 'Locations':
        return LocationIcon;
      case 'Hospital Affiliations':
        return HospitalAffiliationsIcon;
      case 'Users':
        return ProfilesIcon;
      case 'Approvals':
        return NotificationIcon;
      case 'Practices':
        return PracticesIcon;
      case 'Insurances':
        return InsurancesIcon;
      case 'Departments':
        return DepartmentsIcon;
      case 'Other Entities':
        return MenuDotsIcon;
      case 'SEO':
        return WorldIcon;
      default:
        return null;
    }
  }

  checkLicenseRestrictions = (restriction) => {
    const licenseType = this.props.userLicenseType;
    if ( licenseType &&  licenseType.restrictions) {
      return !licenseType.restrictions[restriction];
    }
    return true
  }


  render() {
    const { t } = this.props;
    const { role_id } = this.props.account;
    return (
      <Menu
        mode="inline"
        theme="light"
        className="nav"
        selectedKeys={[this.props.location.pathname]}
      >
        {routes.map( // eslint-disable-line
          route =>
           ((route.showedOnRestrictions && this.checkLicenseRestrictions(route.showedOnRestrictions)) || route.hidden) ? null : (
              <Menu.Item key={route.path}>
                <Icon
                  className="icon nav-icon"
                  component={MenuComponent.getIconByRouteTitle(route.title)}
                />
                <span>{t(route.title)}</span>
                <Link
                  key={`${route.path}tt`}
                  to={route.path}
                  onClick={() => this.props.toggle(true)}
                />
              </Menu.Item>
            ),
        )}
        {role_id === 1 ? (
          <Menu.Item key="/dictionaries">
            <Icon className="icon nav-icon" component={OrganisationsIcon} />
            <span>{t('Dictionaries')}</span>
            <Link to="/dictionaries" onClick={() => this.props.toggle(true)} />
          </Menu.Item>
        ) : null}
        <NavModuleList />
      </Menu>
    );
  }
}

MenuComponent.propTypes = {
  location: PropTypes.objectOf(PropTypes.string),
  toggle: PropTypes.func.isRequired,
  account: PropTypes.shape({
    role_id: PropTypes.number.isRequired,
  }),
  userLicenseType: PropTypes.shape({
    restrictions: PropTypes.shape({
    }),
  }),
  t: PropTypes.func,
};

MenuComponent.defaultProps = {
  location: null,
  account: null,
  t: null,
  userLicenseType: null,
};

export default withTranslation()(MenuComponent);
