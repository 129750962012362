import { SET_INSURANCE, SET_INSURANCES_SET_IS_FETCHING } from '../constants/insurancesActionsTypes';

const initialState = {
  insurance: {},
  isFetching: false,
};

function insurance(state = initialState, action) {
  switch (action.type) {
    case SET_INSURANCE:
      return {
        ...state,
        insurance: action.data,
      };
    case SET_INSURANCES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default insurance;
