import {
  SET_DICTBODYPART,
  SET_DICTBODYPARTS,
  SET_DICTBODYPARTS_SET_IS_FETCHING,
  SET_DICTBODYPARTS_QUERY_PARAMS,
} from '../constants/bodyPartsActionsTypes';

const initialState = {
  list: [],
  params: {
    search: '',
  },
  dictBodyPart: {},
  isFetching: false,
  total: 0,
};

function dictBodyParts(state = initialState, action) {
  switch (action.type) {
    case SET_DICTBODYPART:
      return {
        ...state,
        dictBodyPart: action.data,
      };
    case SET_DICTBODYPARTS:
      return {
        ...state,
        list: action.data.dictBodyParts,
        total: action.data.total,
      };
    case SET_DICTBODYPARTS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTBODYPARTS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictBodyParts;
