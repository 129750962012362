export const LOAD_GMB_LISTINGS_SUCCESS = 'LOAD_GMB_LISTINGS_SUCCESS';

export const SET_GMB_LISTINGS_IS_FETCHING = 'SET_GMB_LISTINGS_IS_FETCHING';
export const SET_GMB_LISTINGS_QUERY_PARAMS = 'SET_GMB_LISTINGS_QUERY_PARAMS';
export const SET_GMB_LISTINGS_SYNCING = 'SET_GMB_LISTINGS_SYNCING';
export const SYNC_GMB_LISTING = 'SYNC_GMB_LISTING';

export const LOAD_GMB_LOCATIONS_START = 'LOAD_GMB_LOCATIONS_START';
export const LOAD_GMB_LOCATIONS_FAILURE = 'LOAD_GMB_LOCATIONS_FAILURE';
export const LOAD_GMB_LOCATIONS_SUCCESS = 'LOAD_GMB_LOCATIONS_SUCCESS';
