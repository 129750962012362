import { FETCH_LICENSES_LIST, FETCH_SINGLE_LICENSE, FETCH_LICENSE_FAIL } from '../constants/licenseActionsTypes';

const initialState = {
  userLicenseType: {},
  licenseLoadFail: false,
  licenses: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10, //eslint-disable-line
    sort: 'created_at,desc',
    search: '',
    scope: ['withRole', 'withTenant'],
  },
  isLoading: false,
  singleUser: {
    id: 0,
    first_name: '', //eslint-disable-line
    last_name: '', //eslint-disable-line
    email: '',
    role: {
      id: 0,
      title: '',
    },
    tenant: null,
  },
};

function licenseReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LICENSES_LIST:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_SINGLE_LICENSE:
      return {
        ...state,
        userLicenseType: action.data,
      };
    case FETCH_LICENSE_FAIL:
      return {
        ...state,
        userLicenseType: null,
        licenseLoadFail: action.error,
      }
    default:
      return state;
  }
}

export default licenseReducer;
