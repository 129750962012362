import find from 'lodash/find';
import * as actionTypes from '../constants/listingsActionTypes';

const initialState = {
  listings: {
    params: {
      per_page: 5,
      page: 1,
      sort: '',
      search: '',
    },
    total: 0,
    isLoading: false,
    isSyncing: false,
    message: null,
    list: [],
    summary: {
      totalCount: 0, listingsCount: 0, gmbCount: 0,
    },
  },
};

const updateListing = (state, action ) => {
  const payload = action.data;
  const filterFulfilled = payload.filter(i => i.status === 'fulfilled').map(l => l.value);

  const findUpdatedListing = (listing, fulfilled) => {
    const foundListing = find(fulfilled, l => l.doctor_id ?
        (listing.doctor_id === l.doctor_id && l.location_id === listing.location_id) :
          l.location_id === listing.location_id && !(listing.doctor_id));

    return foundListing;
  }

  return {
    listings: {
      ...state.listings,
      list: state.listings.list.map(listing => {
          const found = findUpdatedListing(listing, filterFulfilled);
          return found ? {
            ...listing,
            updated_at: found.updatedAt,
            syncAllowed: false,
            listing: { ...listing.listing,
              listing_id: found.listing_id,
              updatedAt: found.updatedAt,
              status: found.status,
            } } :
              listing;
      } ),
    },
  }
}

function listings(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LISTINGS_QUERY_PARAMS:
      return {
        listings: {
          ...state.listings,
          params: {
            ...state.listings.params,
            ...action.params,
          },
        },
      };
    case actionTypes.UPDATE_LISTINGS_SORT_PARAMS:
      return {
        listings: {
          ...state.listings,
          params: {
            ...state.listings.params,
            sort: {
              ...action.payload,
            },
          },
        },
      };
    case actionTypes.LOAD_LISTINGS_START:
      return {
        listings: {
          ...state.listings,
          isLoading: true,
        },
      };
    case actionTypes.LOAD_LISTINGS_SUCCESS:
      return {
        listings: {
          ...state.listings,
          isLoading: false,
          list: action.data.listings,
          total: action.data.count,
        },
      };
    case actionTypes.LOAD_LISTINGS_SUMMARY:
      return {
        listings: {
          ...state.listings,
          summary: action.data.summary,
        },
      };
    case actionTypes.LOAD_LISTINGS_FAILURE:
      return {
        listings: {
          ...state.listings,
          isLoading: false,
        },
      };
    case actionTypes.SYNC_LISTING_GMB_IN_PROGRESS:
      return {
        listings: {
          ...state.listings,
          isSyncing: action.isSyncing,
        },
      };
    case actionTypes.SYNC_LISTING_GMB:
     return  updateListing(state,action);
    case actionTypes.CLEAR_LISTINGS_FILTER:
      return {
        listings: {
          params: {
            ...state.listings.params,
            page: state.listings.params.page,
            per_page: state.listings.params.per_page,
            sort: {
                ...state.listings.params.sort,
            },
          },
          ...state.listings.list,
          ...state.listings.isLoading,
          ...state.listings.message,
          ...state.listings.total,
        },
      };
    case actionTypes.CLEAR_LISTINGS_STORE:
      return {
        listings: {
          ...initialState.listings,
        },
      };

    default:
      return state;
  }
}

export default listings;
