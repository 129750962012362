import find from "lodash/find";
import * as actionTypes from '../constants/gmbListingsActionsTypes';
import updateObject from '../../helpers/updateObject';

const initialState = {
  gmbListings: null,
  locations: [],
  params: {
    per_page: 10,
    page: 1,
    sort: '',
    search: '',
  },
  nextPageToken: null,
  total: null,
  isFetching: false,
  isFetchingLocations: false,
  isListingSyncing: false,
};


const loadGmbListingsSuccess = (state, action) =>
  updateObject(
    state,
  {
    gmbListings: action.data,
    total: action.total,
    nextPageToken: action.nextPageToken,
  });

const updateIsFetching = (state, action) =>
  updateObject(state, {
    isFetching: action.isFetching,
  });

const setGmbListingSyncing = (state, action) =>
  updateObject(state, { isListingSyncing: action.status })

const setGmbListingsParams = (state, action) =>
  updateObject(state, { params: action.params } )

const syncGmbListing = (state, payload) => {
  const { listings } = payload;
  const filterFulfilled = listings.filter(i => i.status === 'fulfilled').map(lf => lf.value);

  const findUpdatedGMB = (gmbListing, fulfilled) => {
    const foundListing = find(fulfilled, lf => (gmbListing.listing_id === lf.listing_id));
    if( foundListing) {
      const updatedListing = {
        ...gmbListing,
        isSyncAllowed: false,
      }
      if (foundListing.location_id) {
        updatedListing.location_id = foundListing.location_id;
      }
      if (foundListing.doctor_id) {
        updatedListing.doctor_id = foundListing.doctor_id;
      }
      return updatedListing;
    }
    return gmbListing;

  };

  return updateObject(state, { gmbListings: state.gmbListings.map(gmbListing =>
        findUpdatedGMB(gmbListing, filterFulfilled))} )
}

const loadGmbLocationsStart = (state) =>
    updateObject(state, { isFetchingLocations: true });

const loadGmbLocationsFail = (state) =>
    updateObject(state, { isFetchingLocations: false });

const loadGmbLocationsSuccess = (state, action) =>
    updateObject(
        state,
        {
          locations: action.data,
          isFetchingLocations: false,
        });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GMB_LISTINGS_SUCCESS:
      return loadGmbListingsSuccess(state, action);

    case actionTypes.SET_GMB_LISTINGS_QUERY_PARAMS:
      return setGmbListingsParams(state, action);

    case actionTypes.SET_GMB_LISTINGS_SYNCING:
      return setGmbListingSyncing(state, action);

    case actionTypes.SYNC_GMB_LISTING:
      return syncGmbListing(state, action);

    case actionTypes.LOAD_GMB_LOCATIONS_START:
      return loadGmbLocationsStart(state);

    case actionTypes.LOAD_GMB_LOCATIONS_FAILURE:
      return loadGmbLocationsFail(state);

    case actionTypes.LOAD_GMB_LOCATIONS_SUCCESS:
      return loadGmbLocationsSuccess(state, action);

    case actionTypes.SET_GMB_LISTINGS_IS_FETCHING:
      return updateIsFetching(state, action);
    default:
      return state;
  }
}

export default reducer;
