export const FETCH_INSURANCE_COMPANIES_START = 'FETCH_INSURANCE_COMPANIES_START';
export const FETCH_INSURANCE_COMPANIES_SUCCESS = 'FETCH_INSURANCE_COMPANIES_SUCCESS';
export const FETCH_INSURANCE_COMPANIES_FAILURE = 'FETCH_INSURANCE_COMPANIES_FAILURE';

export const FETCH_INSURANCE_COMPANY_START = 'FETCH_INSURANCE_COMPANY_START';
export const FETCH_INSURANCE_COMPANY_SUCCESS = 'FETCH_INSURANCE_COMPANY_SUCCESS';
export const FETCH_INSURANCE_COMPANY_FAILURE = 'FETCH_INSURANCE_COMPANY_FAILURE';

export const SET_INSURANCE_COMPANIES_QUERY_PARAMS = 'SET_INSURANCE_COMPANIES_QUERY_PARAMS';

export const FETCH_DICT_INSURANCE_COMPANIES_START = 'FETCH_DICT_INSURANCE_COMPANIES_START';
export const FETCH_DICT_INSURANCE_COMPANIES_SUCCESS = 'FETCH_DICT_INSURANCE_COMPANIES_SUCCESS';
export const FETCH_DICT_INSURANCE_COMPANIES_FAILURE = 'FETCH_DICT_INSURANCE_COMPANIES_FAILURE';

export const SET_DICT_INSURANCE_COMPANIES_QUERY_PARAMS =
  'SET_DICT_INSURANCE_COMPANIES_QUERY_PARAMS';

export const FETCH_DICT_INSURANCE_COMPANY = 'FETCH_DICT_INSURANCE_COMPANY';
export const FETCH_DICT_INSURANCE_COMPANY_SUCCESS = 'FETCH_DICT_INSURANCE_COMPANY_SUCCESS';
