import {
  SET_CURRENT_USER,
  FETCH_USERS_LIST,
  GET_SINGLE_USER,
  SET_USERS_PARAMS,
  CLEAR_SINGLE_USER_STORE,
} from '../constants/userActionsTypes';

const initialState = {
  users: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10, //eslint-disable-line
    sort: 'created_at,desc',
    search: '',
    scope: ['withRole', 'withTenant'],
  },
  isLoading: false,
  singleUser: {
    id: 0,
    first_name: '', //eslint-disable-line
    last_name: '', //eslint-disable-line
    email: '',
    role: {
      id: 0,
      title: '',
    },
    tenant: null,
  },
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.data,
      };
    case FETCH_USERS_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.data,
      };
    case SET_USERS_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case CLEAR_SINGLE_USER_STORE: {
      return {
        ...state,
        singleUser: {},
      };
    }

    default:
      return state;
  }
}

export default userReducer;
