import { SET_LOCATION, SET_LOCATIONS_SET_IS_FETCHING } from '../constants/locationsActionsTypes';

const initialState = {
  location: {},
  isFetching: false,
};

function location(state = initialState, action) {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.data,
      };
    case SET_LOCATIONS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default location;
