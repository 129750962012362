import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import userReducer from './reducers/user.reducer';
import usersReducer from './reducers/users.reducer';
import tenantReducer from './reducers/tenant.reducer';
import metaReducer from './reducers/meta.reducer';
import accountReducer from './reducers/account.reducer';
import rolesReducer from './reducers/roles.reducer';
import filesReducer from './reducers/files.reducer';
import insurancesReducer from './reducers/insurances.reducer';
import dictInsurancesReducer from './reducers/dictInsurances.reducer';
import insuranceReducer from './reducers/insurance.reducer';
import insuranceCompaniesReducer from './reducers/insuranceCompanies.reducer';
import dictInsuranceCompaniesReducer from './reducers/dictInsuranceCompanies.reducer';
import insuranceCompanyReducer from './reducers/insuranceCompany.reducer';
import departmentsReducer from './reducers/departments.reducer';
import departmentReducer from './reducers/department.reducer';
import subspecialtiesReducer from './reducers/subspecialties.reducer';
import subspecialtyReducer from './reducers/subspecialty.reducer';
import proceduresReducer from './reducers/procedures.reducer';
import procedureReducer from './reducers/procedure.reducer';
import hospitalAffiliationReducer from './reducers/hospitalAffiliation.reducer';
import hospitalAffiliationsReducer from './reducers/hospitalAffiliations.reducer';
import hospitalSystemReducer from './reducers/hospitalSystem.reducer';
import hospitalSystemsReducer from './reducers/hospitalSystems.reducer';
import dictProceduresReducer from './reducers/dictProcedures.reducer';
import conditionsReducer from './reducers/conditions.reducer';
import conditionReducer from './reducers/condition.reducer';
import dictConditionsReducer from './reducers/dictConditions.reducer';
import bodyPartsReducer from './reducers/bodyParts.reducer';
import bodyPartReducer from './reducers/bodyPart.reducer';
import dictBodyPartsReducer from './reducers/dictBodyParts.reducer';
import locationReducer from './reducers/location.reducer';
import locationsReducer from './reducers/locations.reducer';
import practicesReducer from './reducers/practices.reducer';
import practiceReducer from './reducers/practice.reducer';
import dictDepartmentsReducer from './reducers/dictDepartments.reducer';
import dictCredentialsReducer from './reducers/dictCredentials.reducer';
import dictLanguagesReducer from './reducers/dictLanguages.reducer';
import dictInjuryTypesReducer from './reducers/dictInjuryTypes.reducer';
import dictSubSpecialtyReducer from './reducers/dictSubSpecialty.reducer';
import doctorReducer from './reducers/doctor.reducer';
import callCenterReducer from './reducers/callCenter.reducer';
import listingsReducer from './reducers/listings.reducer';
import licenseReducer from "./reducers/license.reducer";
import gmbListingsReducer from './reducers/gmbListings.reducer';
import listingReducer from './reducers/listing.reducer';

const reducer = combineReducers({
  meta: metaReducer,
  account: accountReducer, // current user profile
  callCenter: callCenterReducer,
  user: userReducer,
  users: usersReducer,
  tenant: tenantReducer,
  roles: rolesReducer,
  files: filesReducer,
  insurances: insurancesReducer,
  dictInsurances: dictInsurancesReducer,
  insurance: insuranceReducer,
  insuranceCompanies: insuranceCompaniesReducer,
  dictInsuranceCompanies: dictInsuranceCompaniesReducer,
  insuranceCompany: insuranceCompanyReducer,
  departments: departmentsReducer,
  department: departmentReducer,
  subspecialties: subspecialtiesReducer,
  subspecialty: subspecialtyReducer,
  procedures: proceduresReducer,
  procedure: procedureReducer,
  dictProcedures: dictProceduresReducer,
  conditions: conditionsReducer,
  condition: conditionReducer,
  dictConditions: dictConditionsReducer,
  bodyParts: bodyPartsReducer,
  bodyPart: bodyPartReducer,
  dictBodyParts: dictBodyPartsReducer,
  location: locationReducer,
  locations: locationsReducer,
  hospitalAffiliation: hospitalAffiliationReducer,
  hospitalAffiliations: hospitalAffiliationsReducer,
  hospitalSystem: hospitalSystemReducer,
  hospitalSystems: hospitalSystemsReducer,
  practices: practicesReducer,
  practice: practiceReducer,
  dictDepartments: dictDepartmentsReducer,
  dictCredentials: dictCredentialsReducer,
  dictLanguages: dictLanguagesReducer,
  dictInjuryTypes: dictInjuryTypesReducer,
  dictSubSpecialty: dictSubSpecialtyReducer,
  doctor: doctorReducer,
  listings: listingsReducer,
  listing: listingReducer,
  license: licenseReducer,
  gmbListings: gmbListingsReducer,
});

export default createStore(reducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));
