import React, { Component } from 'react';
import BaseDAO from './DAO';
import api from '../config/api';

class HealthCheckDAO extends BaseDAO {
  constructor() {
    super({ baseUrl: `${api.ms.triage}/health-check` });

    const parent = this.public;

    this.public = {
      healthCheck: ({ ...options }) => parent.get({ ...options }),
    };
  }
}

const DAO = new HealthCheckDAO();

export const HealthCheck = DAO.public;

export default WrappedComponent =>
  class HealthCheckHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} healthCheckDAO={HealthCheck} />;
    }
  };
