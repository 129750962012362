import { Spin, Menu } from 'antd';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import reject from 'lodash/reject';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BIModuleIcon, PGModuleIcon, TTModuleIcon } from '../Icons';

import frontendApps from '../../config/frontendApps';
import licensesDAO from '../../DAO_hocs/licensesDAO_HOC';

@connect(
  store => ({
    account: get(store, 'account.account'),
  }),
  null,
)
@licensesDAO
class NavModuleList extends Component {
  static getModuleLink(moduleId) {
    switch (moduleId) {
      case 1:
        return frontendApps.tt;
      case 2:
        return frontendApps.bi;
      default:
        return frontendApps.portal;
    }
  }

  static getModuleIcon(moduleId) {
    switch (moduleId) {
      case 1:
        return <TTModuleIcon />;
      case 2:
        return <BIModuleIcon />;
      default:
        return <PGModuleIcon />;
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      moduleList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.loadLicenseModules();
  }

  loadLicenseModules = async () => {
    this.setState({
      loading: true,
    });
    try {
      const roleId = get(this.props, 'account.role_id');
      let moduleList = [];
      const pgPortalModule = { id: null, title: 'PG Portal' };

      if (roleId === 1) {
        // if admin or client admin - show all modules (except TT)
        const { data } = await invoke(this.props, 'licensesDAO.getModules');

        moduleList = map(get(data, 'data.modules'), module => {
          if (module.id === 1) {
            // is module TT
            return pgPortalModule;
          }
          return { id: module.id, title: module.title };
        });

        this.setState({
          moduleList,
        });
      } else {
        // else - show modules that user has license (except TT)
        const licensesList = reject(map(get(this.props, 'account.tenant.licenses', []), 'module'), [
          'id',
          1,
        ]);
        if (roleId === 2) {
          licensesList.push(pgPortalModule);
        }
        this.setState({
          moduleList: licensesList,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { moduleList, loading } = this.state;
    if (loading) return <Spin />;
    if (!moduleList) return null;

    return (
      <Menu selectedKeys={[]} defaultSelectedKeys={[]}>
        <Menu.Divider className="ant-menu-item-divider" />
        {!isEmpty(moduleList) &&
          map(moduleList, ({ id, title }) => (
            <Menu.Item
              title={`${title} ${invoke(this.props, 't', 'Module')}`}
              className="ant-menu-item nav-link"
              key={id}
            >
              <a href={NavModuleList.getModuleLink(id)} target="_blank" rel="noopener noreferrer">
                {NavModuleList.getModuleIcon(id)}
              </a>
            </Menu.Item>
          ))}
      </Menu>
    );
  }
}

NavModuleList.propTypes = {
  account: PropTypes.shape({
    tenant: PropTypes.shape({
      licenses: PropTypes.shape({
        module_id: PropTypes.number,
      }),
    }),
    role_id: PropTypes.number,
  }),
  // eslint-disable-next-line react/no-unused-prop-types
  licensesDAO: PropTypes.objectOf(PropTypes.func),
};

NavModuleList.defaultProps = {
  account: {},
  licensesDAO: null,
};

export default withTranslation()(NavModuleList);
