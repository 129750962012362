import {
  SET_DICTSUBSPECIALTY,
  SET_DICTSUBSPECIALTIES,
  SET_DICTSUBSPECIALTY_SET_IS_FETCHING,
  SET_DICTSUBSPECIALTIES_QUERY_PARAMS,
} from '../constants/subspecialtiesActionsTypes';

const initialState = {
  instance: {},
  params: {
    search: '',
  },
  list: [],
  isFetching: false,
  total: 0,
};

function dictSubSpecialty(state = initialState, action) {
  switch (action.type) {
    case SET_DICTSUBSPECIALTY:
      return {
        ...state,
        instance: action.data,
      };
    case SET_DICTSUBSPECIALTIES:
      return {
        ...state,
        list: action.data.dictSubSpecialties,
        total: action.data.total,
      };
    case SET_DICTSUBSPECIALTIES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_DICTSUBSPECIALTY_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default dictSubSpecialty;
