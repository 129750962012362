import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'antd';

const transformUsername = str => {
  const userArr = str.split(' ');
  let result = '';
  if (userArr.length === 1) {
    result = userArr[0][0];
  } else {
    userArr.forEach(namePart => {
      result += namePart[0];
    });
  }

  return result.toUpperCase();
};

class AvatarComponent extends Component {
  render() {
    const { username, src, id, basepath, useBasePath, ...rest } = this.props;
    const basePath = basepath.startsWith('/') ? basepath.slice(1) : basepath;
    const source = useBasePath ? src || `/api/files-ms/files/media/${basePath}/${id}` : src;
    if (username) {
      return (
        <Avatar src={source} {...rest}>
          {transformUsername(username)}
        </Avatar>
      );
    }
    return <Avatar icon="user" {...rest} />;
  }
}

AvatarComponent.propTypes = {
  src: PropTypes.string,
  username: PropTypes.string,
  basepath: PropTypes.string,
  id: PropTypes.number,
  useBasePath: PropTypes.bool,
};

AvatarComponent.defaultProps = {
  src: null,
  username: null,
  basepath: '/users',
  id: null,
  useBasePath: true,
};

export default AvatarComponent;
