import {
  FETCH_HOSPITAL_SYSTEMS_START,
  FETCH_HOSPITAL_SYSTEMS_SUCCESS,
  FETCH_HOSPITAL_SYSTEMS_FAILURE,
  SET_HOSPITAL_SYSTEMS_QUERY_PARAMS,
} from '../constants/hospitalSystemsActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 5,
    page: 1,
    sort: 'created_at,desc',
    search: '',
    scope: ['withTenant'],
  },
  total: 0,
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
};

function hospitalSystems(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOSPITAL_SYSTEMS_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case FETCH_HOSPITAL_SYSTEMS_SUCCESS:
      return {
        ...state,
        list: action.payload.hospitalSystems,
        total: action.payload.total,
        isStarted: false,
        isLoad: true,
        isError: false,
      };
    case FETCH_HOSPITAL_SYSTEMS_FAILURE:
      return {
        ...state,
        list: [],
        isStarted: false,
        isLoad: true,
        isError: true,
        msg: action.payload,
      };
    case SET_HOSPITAL_SYSTEMS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    default:
      return state;
  }
}

export default hospitalSystems;
