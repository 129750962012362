import axios from "axios";
import i18n from '../../i18n';

let auth2;
let loadingPromise;

const createScript = () => new Promise((resolve) => {
    const el = document.getElementById('auth2_script_id');
    if (!el) {
      const gplatformScript = document.createElement('script');
      gplatformScript.setAttribute('src', 'https://apis.google.com/js/platform.js?onload=onGapiLoad');
      gplatformScript.setAttribute('async', true);
      gplatformScript.setAttribute('defer', 'defer');
      gplatformScript.setAttribute('id', 'auth2_script_id');
      document.head.appendChild(gplatformScript);
    }
    resolve();
  });

const createOneTapScript = () => new Promise((resolve) => {
    const el = document.getElementById('onetap_script_id');
    if (!el) {
      const gplatformScript = document.createElement('script');
      gplatformScript.setAttribute('src', 'https://accounts.google.com/gsi/client');
      gplatformScript.setAttribute('async', true);
      gplatformScript.setAttribute('defer', 'defer');
      gplatformScript.setAttribute('id', 'onetap_script_id');
      document.head.appendChild(gplatformScript);
    }
    resolve();
  });

const onGapiLoadPromise = (params) => new Promise((resolve, reject) => {
    window.onGapiLoad = () => {
      window.gapi.load('auth2', () => {
        try {
          auth2 = window.gapi.auth2.init(
            Object.assign({
                scope: 'https://www.googleapis.com/auth/plus.business.manage',
            }, params),
          );
        } catch (err) {
          console.error('err GAPI', err);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ err: i18n.t('client_id missing or is incorrect, or if you added extra params maybe they are written incorrectly, did you add it to the component or plugin?') });
        }
        resolve(auth2);
      });
    };
  });

const loadingAuth2 = (params) => {
  if (auth2) {
    return Promise.resolve(auth2);
  }
    if (!loadingPromise) { loadingPromise = onGapiLoadPromise(params); }
    return loadingPromise;

};

const load = (params) => Promise.all(
    [loadingAuth2(params), createScript(), createOneTapScript()])
    .then((results) => results[0]);

const wrapper = (f, method) => {
  if (f) { return f[method](); }
    const err = { err: i18n.t('Script not loaded correctly, did you added the plugin or the client_id to the component?') };
    return Promise.reject(err);
};

const wrapCaller = (f, method, params) => {
    if (f) {
        return f[method](params);
    }
    return Promise.reject(Error("wrapper caller is not found"));
};

// Here are choosing the first Group account associated with this Google account,
// if you will need to select between them or use the personal one - please add extra logic it
const getCorrectGroupAccount = (accounts) => {
    console.info('accounts', accounts);
    const account = accounts.filter(a => a.type === "LOCATION_GROUP");
    if (!account.length) {
        return { error: i18n.t('No Group listing accounts associated with this Google account. ' +
                'Please connect a different Google account.') };
    }
    // pick the first one!
    return account[0].name;
};

const handleGetAccountID = async (accessToken) => {
    try {
        const response = await axios.get('https://mybusiness.googleapis.com/v4/accounts',
            {
                headers: {
                    Authorization: `OAuth ${accessToken}`,
                },
            });

        if (!response || !response.data) {
            throw new Error(i18n.t("error getting an account ID"));
        }

        console.info(response.data, 'response');
        return response.data && getCorrectGroupAccount(response.data.accounts);
    } catch (e) {
        if (e.response.data.error && e.response.data.error.code === 403) {
            return { error: i18n.t('No business listings associated with this Google account. ' +
                    'Please connect a different Google account.') };
        }
        return { error: e.response.data.error };
    }
};

const signIn = () => wrapper(auth2, 'signIn');

const signOut = () => wrapper(auth2, 'signOut');

const isSignedIn = () => wrapper(auth2.isSignedIn, 'get');

const currentUser = () => wrapper(auth2.currentUser, 'get');
const listenUser = (params) => wrapCaller(auth2.currentUser, 'listen', params);
const reloadAuthResponse = () => wrapper(auth2, 'reloadAuthResponse');

const grantOfflineAccess = () => wrapper(auth2, 'grantOfflineAccess');

export default {
  load,
  signIn,
  signOut,
  isSignedIn,
  currentUser,
  listenUser,
  reloadAuthResponse,
  grantOfflineAccess,

  handleGetAccountID,
};
