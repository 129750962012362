import React from 'react';
import withPermission from '../helpers/withPermission';
import * as PERMISSIONS from '../config/permissions';

const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const DnDDoctors = React.lazy(() => import('./DnDDoctors/DnDDoctors'));

const DoctorProfile = withPermission(PERMISSIONS.DOCTORS.READ, { redirect: true })(
  React.lazy(() => import('./DoctorProfile/DoctorProfile')),
);

const Locations = withPermission(PERMISSIONS.LOCATIONS.READ, { redirect: true })(
  React.lazy(() => import('./Locations/Locations')),
);

const ViewLocation = withPermission(PERMISSIONS.LOCATIONS.READ, { redirect: true })(
  React.lazy(() => import('./Locations/ViewLocation/ViewLocation')),
);

const HospitalAffiliations = withPermission(PERMISSIONS.HOSPITAL_AFFILIATIONS.READ, {
  redirect: true,
})(React.lazy(() => import('./HospitalAffiliations')));

const ViewHospitalAffiliations = withPermission(PERMISSIONS.HOSPITAL_AFFILIATIONS.READ, {
  redirect: true,
})(
  React.lazy(() =>
    import('./HospitalAffiliations/ViewHospitalAffiliation/ViewHospitalAffiliation'),
  ),
);

const Insurances = withPermission(PERMISSIONS.INSURANCES.READ, { redirect: true })(
  React.lazy(() => import('./Insurances')),
);

const Departments = withPermission(PERMISSIONS.DEPARTMENTS.READ, { redirect: true })(
  React.lazy(() => import('./Departments')),
);

const BodyParts = withPermission(PERMISSIONS.BODY_PARTS.READ, { redirect: true })(
  React.lazy(() => import('./BodyParts')),
);

const Conditions = withPermission(PERMISSIONS.CONDITIONS.READ, { redirect: true })(
  React.lazy(() => import('./Conditions')),
);

const Procedures = withPermission(PERMISSIONS.PROCEDURES.READ, { redirect: true })(
  React.lazy(() => import('./Procedures')),
);

const Practices = withPermission(PERMISSIONS.PRACTICES.READ, { redirect: true })(
  React.lazy(() => import('./Practices')),
);

const Users = withPermission(PERMISSIONS.USERS.READ, { redirect: true })(
  React.lazy(() => import('./Users')),
);

const OtherEntities = React.lazy(() => import('./OtherEntities'));

const Approvals = withPermission(PERMISSIONS.APPROVALS.READ, { redirect: true })(
  React.lazy(() => import('./Approvals')),
);

const Comparison = withPermission(PERMISSIONS.APPROVALS.UPDATE, { redirect: true })(
  React.lazy(() => import('./Comparison')),
);

const ComparisonLocation = withPermission(PERMISSIONS.APPROVALS.UPDATE, { redirect: true })(
    React.lazy(() => import('./ComparisonLocation')),
);

const Dictionaries = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries')),
);

const DictionaryBodyParts = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/BodyParts')),
);

const DictionaryConditions = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Conditions')),
);

const DictionaryProcedures = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Procedures')),
);

const DictionaryDepartments = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Departments')),
);

const DictionaryCredentials = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Credentials')),
);

const DictionaryLanguages = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Languages')),
);

const DictionaryInjuryTypes = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/InjuryTypes')),
);

const DictionaryInsurances = withPermission(PERMISSIONS.DICTIONARIES.CREATE, { redirect: true })(
  React.lazy(() => import('./Dictionaries/Insurances')),
);

const DictionarySubSpecialties = withPermission(PERMISSIONS.DICTIONARIES.CREATE, {
  redirect: true,
})(React.lazy(() => import('./Dictionaries/SubSpecialties')));

const Seo = withPermission(PERMISSIONS.GMB.READ,{
  redirect: true,
})(React.lazy(() => import('./Seo')));

const GmbListings = withPermission(PERMISSIONS.GMB.READ, {
  redirect: true,
})(React.lazy(() => import('./GmbListings')))

const NotFound = React.lazy(() => import('./NotFound/NotFound'));

export default [
  {
    path: '/',
    title: 'Dashboard',
    component: Dashboard,
    exact: true,
  },
  {
    path: '/seo',
    title: 'SEO',
    component: Seo,
    showedOnRestrictions: 'GMB',
    exact: true,
  },
  {
    path: '/gmbListings',
    title: 'Gmb Listings',
    component: GmbListings,
    hidden: true,
    exact: true,
  },
  {
    path: '/doctors/:slug',
    title: 'Doctor Profile',
    component: DoctorProfile,
    hidden: true,
    exact: true,
  },
  {
    path: '/users',
    title: 'Users',
    component: Users,
    exact: true,
  },
  {
    path: '/users/order',
    title: 'Users Order',
    component: DnDDoctors,
    exact: true,
    hidden: true,
  },
  {
    path: '/approvals',
    title: 'Approvals',
    component: Approvals,
    exact: true,
  },
  {
    path: '/hospital-affiliations',
    title: 'Hospital Affiliations',
    component: HospitalAffiliations,
    exact: true,
  },
  {
    path: '/hospital-affiliations/:id',
    title: 'View Hospital Affiliations',
    component: ViewHospitalAffiliations,
    hidden: true,
    exact: true,
  },
  {
    path: '/insurances',
    title: 'Insurances',
    component: Insurances,
    exact: true,
  },
  {
    path: '/departments',
    title: 'Departments',
    component: Departments,
    exact: true,
  },
  {
    path: '/locations',
    title: 'Locations',
    component: Locations,
    exact: true,
  },
  {
    path: '/locations/:id',
    title: 'View Location',
    component: ViewLocation,
    hidden: true,
    exact: true,
  },
  {
    path: '/practices',
    title: 'Practices',
    component: Practices,
    exact: true,
  },

  {
    path: '/other-entities',
    title: 'Other Entities',
    component: OtherEntities,
    exact: true,
  },
  {
    path: '/body-parts',
    title: 'Body Parts',
    component: BodyParts,
    hidden: true,
    exact: true,
  },
  {
    path: '/conditions',
    title: 'Conditions',
    component: Conditions,
    hidden: true,
    exact: true,
  },
  {
    path: '/procedures',
    title: 'Procedures',
    component: Procedures,
    hidden: true,
    exact: true,
  },
  {
    path: '/comparison',
    title: 'Comparison',
    component: Comparison,
    hidden: true,
    exact: true,
  },
  {
    path: '/comparison-location',
    title: 'Comparison location',
    component: ComparisonLocation,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries',
    title: 'Dictionaries',
    component: Dictionaries,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/body-parts',
    title: 'Dictionary Body Parts',
    component: DictionaryBodyParts,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/conditions',
    title: 'Dictionary Conditions',
    component: DictionaryConditions,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/procedures',
    title: 'Dictionary Procedures',
    component: DictionaryProcedures,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/departments',
    title: 'Dictionary Departments',
    component: DictionaryDepartments,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/credentials',
    title: 'Dictionary Credentials',
    component: DictionaryCredentials,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/languages',
    title: 'Dictionary Languages',
    component: DictionaryLanguages,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/injury-types',
    title: 'Dictionary Injury Types',
    component: DictionaryInjuryTypes,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/insurances',
    title: 'Dictionary Insurances',
    component: DictionaryInsurances,
    hidden: true,
    exact: true,
  },
  {
    path: '/dictionaries/sub-specialties',
    title: 'Dictionary Sub Specialties',
    component: DictionarySubSpecialties,
    hidden: true,
    exact: true,
  },
  {
    path: '*',
    component: NotFound,
    hidden: true, // hide from navigation
  },
];
