import axios from 'axios';
import get from 'lodash/get';
import merge from 'lodash/merge';
import refreshRequestStore from './refreshRequest';

import { Sts } from './stsDAO_HOC';
import frontendApps from '../config/frontendApps';

export default class Api {
  constructor(options = {}) {
    this.client =
      options.client ||
      axios.create({
        baseURL: options.baseUrl,
      });
    this.baseURL = options.baseUrl;
    this.stsClient = Sts;
    this.defaultOptions = {
      DELETE: {
        params: {
          force: true,
        },
      },
    };
    // This we checking IE, than adding pragma to headers
    this.isIE11 = /Trident.*rv[ :]*11\./.test(navigator.userAgent);
    this.header = this.isIE11 ? { headers: { pragma: 'no-cache' } } : '';

    this.client.interceptors.response.use(
      r => r,
      async error => {
        if (error.response.status === 403) {
          console.error('Forbidden 403', window.location.href);

          if (window.location.pathname !== '/') {
            window.location.replace(`${frontendApps.tt}`);
          }
        }
        if (error.response.status === 401) {
          console.error('Unauthorized 401', window.location.href);
          window.location.replace(`${frontendApps.auth}/login`);
        }

        if (!error.response || error.response.status !== 401 || error.config.retry) {
          // reassign default error message to response message ( if exist )
          const message = get(error, 'response.data.message', error.message);
          const reassignError = Object.assign(error, { message });
          return Promise.reject(reassignError);
        }
        const refreshRequest = refreshRequestStore.getRefreshRequest();
        if (!refreshRequest) {
          // set Promise to refresh request
          refreshRequestStore.setRefreshRequest(this.stsClient.refreshToken());
        }
        // wait for refresh
        await refreshRequestStore.getRefreshRequest();
        // create new config
        const newRequest = {
          ...error.config,
          baseURL: '', // prevent url concatenation during retry process
          retry: true, // retry trigger, prevent infinite retry loop
        };
        // retry failed request
        return this.client(newRequest);
      },
    );

    this.appLogout = () => this.stsClient.appLogout();

    this.public = {
      get: ({ url = '', ...rest }) => this.client.get(url, merge(this.header, rest)),

      post: ({ url = '', ...rest }) => this.client.post(url, rest),

      put: ({ url = '', ...rest }) => this.client.put(url, rest),

      patch: ({ url = '', ...rest }) => this.client.patch(url, rest),

      delete: ({ url = '', ...rest }) =>
        this.client.delete(url, merge(this.defaultOptions.DELETE, rest)),

      defaultPost: this.client.post,
    };
  }
}
