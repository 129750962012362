export const SET_INSURANCE = 'SET_INSURANCE';
export const SET_INSURANCES = 'SET_INSURANCES';
export const SET_INSURANCES_QUERY_PARAMS = 'SET_INSURANCES_QUERY_PARAMS';
export const SET_INSURANCES_SET_IS_FETCHING = 'SET_INSURANCES_SET_IS_FETCHING';

export const FETCH_DICT_INSURANCES_START = 'FETCH_DICT_INSURANCES_START';
export const FETCH_DICT_INSURANCES_SUCCESS = 'FETCH_DICT_INSURANCES_SUCCESS';
export const FETCH_DICT_INSURANCES_FAILURE = 'FETCH_DICT_INSURANCES_FAILURE';

export const SET_DICT_INSURANCES_QUERY_PARAMS = 'SET_DICT_INSURANCES_QUERY_PARAMS';

export const FETCH_DICT_INSURANCE = 'FETCH_DICT_INSURANCE';
export const FETCH_DICT_INSURANCE_SUCCESS = 'FETCH_DICT_INSURANCE_SUCCESS';
