import {
  FETCH_INSURANCE_COMPANY_START,
  FETCH_INSURANCE_COMPANY_SUCCESS,
  FETCH_INSURANCE_COMPANY_FAILURE,
} from '../constants/insuranceCompaniesActionsTypes';

const initialState = {
  instance: {},
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
};

function insuranceCompany(state = initialState, action) {
  switch (action.type) {
    case FETCH_INSURANCE_COMPANY_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case FETCH_INSURANCE_COMPANY_SUCCESS:
      return {
        ...state,
        instance: action.payload,
        isStarted: false,
        isLoad: true,
        isError: false,
      };
    case FETCH_INSURANCE_COMPANY_FAILURE:
      return {
        ...state,
        instance: {},
        isStarted: false,
        isLoad: true,
        isError: true,
        msg: action.payload,
      };
    default:
      return state;
  }
}

export default insuranceCompany;
