import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appForbidden } from '../redux/actions/meta.action';

export default (allowedPermission, { redirect } = {}) => WrappedComponent => {
  @connect(
    store => ({
      permissions: store.account.account.role.permissions,
    }),
    {
      appForbidden,
    },
  )
  class WithPermission extends React.Component {
    static propTypes = {
      permissions: PropTypes.arrayOf(PropTypes.shape({})),
      history: PropTypes.shape({
        goBack: PropTypes.func,
      }),
      appForbidden: PropTypes.func.isRequired,
    };

    static defaultProps = {
      permissions: [],
      history: null,
    };

    componentDidMount() {
      const { permissions, history } = this.props;
      if (redirect && history && !this.isValidPermission(allowedPermission, permissions)) {
        // return history.goBack();
        this.props.appForbidden();
      }
      // return false;
    }

    isValidPermission = (allowed, permissions) =>
      !permissions.length || !!_.find(permissions, { title: allowed });

    render() {
      const { permissions } = this.props;
      const isAllowed = this.isValidPermission(allowedPermission, permissions);

      return <WrappedComponent {...this.props} isAllowed={isAllowed} />;
    }
  }

  return WithPermission;
};
