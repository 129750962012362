import {
  FETCH_TENANTS_LIST,
  GET_SINGLE_TENANT,
  SET_TENANT_PARAMS,
  FETCH_MINIFIED_TENANTS_LIST,
} from '../constants/tenantActionsTypes';

const initialState = {
  tenants: [],
  total: 0,
  params: {
    page: 1,
    per_page: 10, //eslint-disable-line
    sort: 'created_at,desc',
    search: '',
    scope: ['withLicense', 'withMainLocation'],
  },
  isLoading: false,
  singleTenant: {
    id: 0,
    company_name: '', //eslint-disable-line
    main_phone_number: '', //eslint-disable-line
    company_website: '', //eslint-disable-line
    licenses: [{}],
    locations: [{ city: '', state: '' }],
    address: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
  },
};

function tenantReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TENANTS_LIST:
      return {
        ...state,
        ...action.data,
      };
    case GET_SINGLE_TENANT:
      return {
        ...state,
        singleTenant: action.data,
      };
    case SET_TENANT_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case FETCH_MINIFIED_TENANTS_LIST:
      return {
        ...state,
        tenantsList: action.tenantsList,
      };
    default:
      return state;
  }
}

export default tenantReducer;
