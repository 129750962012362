import * as actions from '../actions/account.action';

import { Users } from '../../DAO_hocs/usersDAO_HOC';
import { Sts } from '../../DAO_hocs/stsDAO_HOC';

import getLicense from '../actions/license.action'
import { loadRolesList } from './roles.controller';
import {showErrorToast} from "../actions/meta.action";
import GoogleAuth from "../../components/GAuth/comGoogleLogin";
import { loadListings } from './listing.controller';

const afterAccountLoadActions = [loadRolesList, getLicense];

export const logoutFromGoogle = () => {
  if (typeof google !== 'undefined') {
    // const auth2 = window.gapi.auth2.getAuthInstance();
    GoogleAuth.signOut().then(async () => {
      console.info('Logged out from Google account');
    });
  }
};

export const loadAccount = () => async dispatch => {
  await dispatch(actions.loadAccountStart());
  try {
    const response = await Users.whoAmI({
      params: {
        scope: ['withPermission', 'withAvatar', 'withLicense', 'withGMB'],
      },
    });
    const account = response.data.data;
    await dispatch(actions.loadAccountSuccess(account));
    const promises = afterAccountLoadActions.map(
      actionFunc => new Promise(resolve => resolve(dispatch(actionFunc()))),
    );
    Promise.all(promises);
  } catch (e) {
    await dispatch(actions.loadAccountFailure(e.message));
  }
};

export const clearAccount = () => async dispatch => {
  await dispatch(actions.clearAccountStore());
};

export const logout = () => async dispatch => {
  try {
    await Sts.appLogout();

    // logout user from Google account; NOTE: we won't detach GMB account
    logoutFromGoogle();
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(clearAccount());
  }
};

export const signinGMBAccount = (payload) => async dispatch => {
  try {
    const response = await Users.authenticateGMBAccount(payload);
    const account = response.data.data;
    await dispatch(actions.authenticateGMBAccount(account));
    const promises = afterAccountLoadActions.map(
        actionFunc => new Promise(resolve => resolve(dispatch(actionFunc()))),
    );
    Promise.all(promises);
    await dispatch(loadListings());
  } catch (e) {
    console.error(`error updating an account: ${e.message}`);
    dispatch(showErrorToast(e.message));
  }
};

export const logoutGMBAccount = () => async dispatch => {
  try {
    const response = await Users.logoutGMBAccount();
    const account = response.data.data;
    await dispatch(actions.authenticateGMBAccount(account));
    const promises = afterAccountLoadActions.map(
        actionFunc => new Promise(resolve => resolve(dispatch(actionFunc()))),
    );
    Promise.all(promises);
  } catch (e) {
    console.error(`error updating an account after GMB remove: ${e.message}`);
    dispatch(showErrorToast(e.message));
  }
};
