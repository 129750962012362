import { SET_DOCTOR, SET_DOCTORS_SET_IS_FETCHING } from '../constants/doctorActionTypes';

const initialState = {
  doctor: {},
  isFetching: false,
};

function doctor(state = initialState, action) {
  switch (action.type) {
    case SET_DOCTOR:
      return {
        ...state,
        doctor: action.data,
      };
    case SET_DOCTORS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default doctor;
