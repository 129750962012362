import {
  SET_USER,
  SET_USERS,
  SET_USERS_QUERY_PARAMS,
  SET_USERS_SET_IS_FETCHING,
} from '../constants/usersActionsTypes';

const initialState = {
  list: [],
  user: {},
  params: {
    per_page: 10,
    page: 1,
    sort: 'created_at,desc',
    search: '',
    scope: ['withRole', 'withTenant', 'TTAndOtherModulesUsers', 'withAvatar'],
  },
  isFetching: false,
  total: 0,
};

function users(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        list: action.data.users,
        params: {
          ...state.params,
          ...action.data.pagination,
        },
        total: action.data.total,
      };
    case SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case SET_USERS_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_USERS_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default users;
