import { Layout, Alert } from 'antd';
import get from 'lodash/get';
import includes from 'lodash/includes';
import invoke from 'lodash/invoke';
import isBoolean from 'lodash/isBoolean';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './Layout.less';

import Header from './Header';
import Navigation from './Navigation';
import { roles } from "../../config/permissions";

const AdminAlertMessage = withTranslation()(props => (
  <div>
    <p>{invoke(props, 't', 'You Are in Admin Mode.')}</p>
    <p>
      {invoke(props, 't', 'Some Functionality Regarding Creating Flow is not accessible for you')}
    </p>
  </div>
));

@connect(store => ({ role: store.account.account.role }))
class LayoutComponent extends Component {
  state = {
    collapsed: true,
    showAdminAlert: this.props.isAdmin,
  };

  siderToggle = status => {
    this.setState(state => ({
      collapsed: isBoolean(status) ? status : !state.collapsed,
    }));
  };

  adminAlertClose = () => {
    this.setState({
      showAdminAlert: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showAdminAlert && (
          <Alert
            message={invoke(this.props, 't', 'Warning')}
            description={<AdminAlertMessage />}
            type="warning"
            showIcon
            closable
            onClose={this.adminAlertClose}
            style={{
              margin: '0 auto 5px auto',
              width: '100%',
              position: 'fixed',
              zIndex: 100,
            }}
          />
        )}
        <Layout className="layout">
          <Header toggle={this.siderToggle} />
          <Layout>
            {includes([roles.admin, roles.customerAdminPlus, roles.clientAdmin], get(this.props, 'role.title')) && (
              <Navigation collapsed={this.state.collapsed} toggle={this.siderToggle} />
            )}
            <Layout.Content className="content">{this.props.children}</Layout.Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.shape({}),
  isAdmin: PropTypes.bool,
};

LayoutComponent.defaultProps = {
  role: null,
  isAdmin: false,
};

export default withTranslation()(LayoutComponent);
