import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tag, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import invoke from 'lodash/invoke';

import frontendApps from '../../config/frontendApps';
import './Forbidden.less';

@connect(store => ({
  errMessage: get(store, 'meta.errMessage'),
}))
class Forbidden extends Component {
  render() {
    return (
      <div className={classNames('forbidden-root', this.props.rootClassName)}>
        <div className="left-side">
          <div className="shapes">
            <div className="shape-1" />
            <div className="shape-2" />
            <div className="shape-3" />
            <div className="shape-4" />
            <div className="shape-5" />
          </div>
          <div className="content">
            <div className="main-part">
              <div className="label">
                <span>ADMIN</span>
                <span>PORTAL</span>
              </div>
              <div className="message">
                <div className="title">{invoke(this.props, 't', 'Oops!')}</div>
                <div className="text">
                  {get(this.props, 'errMessage') ||
                    invoke(this.props, 't', `You don't have permission to access this page`)}
                </div>
                <div className="error">
                  <span>{invoke(this.props, 't', 'Error code')}: </span>
                  <span>403</span>
                </div>
              </div>
              <Tag className="forbidden-button">
                <a href={frontendApps.auth}>{invoke(this.props, 't', 'Go Back')}</a>
                <Icon type="right" />
              </Tag>
            </div>
          </div>
        </div>
        <div className="right-side" />
      </div>
    );
  }
}

Forbidden.propTypes = {
  rootClassName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  errMessage: PropTypes.string,
};

Forbidden.defaultProps = {
  rootClassName: '',
  errMessage: null,
};

export default withTranslation()(Forbidden);
