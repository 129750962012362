/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import createDAO from './DAO';
import api from '../config/api';

class RolesDAO extends createDAO {
  constructor() {
    super({ baseUrl: `${api.ms.users}/roles` });
    const parent = this.public;

    this.public = {
      getList: ({ ...options }) => parent.get({ url: '', ...options }),

      getById: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.get({ url: `/${id}`, ...options });
      },

      create: ({ ...options }) => parent.post({ url: '', ...options }),

      update: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.put({ url: `/${id}`, ...options });
      },

      delete: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.delete({ url: `/${id}`, ...options });
      },

      getRolePermissions: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.get({ url: `/${id}/permissions`, ...options });
      },

      updateRolePermissions: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.put({ url: `/${id}/permissions`, ...options });
      },
    };
  }
}

const DAO = new RolesDAO();

export const Roles = DAO.public;

export default WrappedComponent =>
  class RoleDAO extends Component {
    render() {
      return <WrappedComponent {...this.props} rolesDAO={Roles} />;
    }
  };
