/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import createDAO from './DAO';
import api from '../config/api';

class ListingsDAO extends createDAO {
  constructor() {
    super({ baseUrl: `${api.ms.triage}/listings` });
    const parent = this.public;

    this.public = {
      getListings: ({ ...options }) => parent.get({ url: '', ...options }),

      getById: ({ ...options }) => parent.get({ url: `/detailed`, ...options }),

      account: (authToken) => parent.get({ url: `/account/${authToken}` }),

      summary: () => parent.get({ url: '/summary' }),

      search: ({ ...options }) => parent.get({ url: '/search', ...options }),

      syncListingGMB: ({ ...options }) => parent.post({ url: '/gmb', ...options }),

      statuses: () => parent.get({ url: '/statuses' }),

      create: ({ ...options }) => parent.post({ url: `/`, ...options }),

      delete: ({ id, ...options }) => {
        if (!id) return Promise.reject('id is required');
        return parent.delete({ url: `/${id}`, ...options });
      },

    };
  }
}

const DAO = new ListingsDAO();

export const Listings = DAO.public;

export default WrappedComponent =>
  class ListingsDAOHOC extends Component {
    render() {
      return <WrappedComponent {...this.props} ListingsDAO={Listings} />;
    }
  };
