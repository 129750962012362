import {
  SET_SUBSPECIALTY,
  SET_SUBSPECIALTIES_SET_IS_FETCHING,
} from '../constants/subspecialtiesActionsTypes';

const initialState = {
  subspecialty: {},
  isFetching: false,
};

function subspecialty(state = initialState, action) {
  switch (action.type) {
    case SET_SUBSPECIALTY:
      return {
        ...state,
        subspecialty: action.data,
      };
    case SET_SUBSPECIALTIES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default subspecialty;
