import * as actionTypes from '../constants/filesActionTypes';

const initialState = {
  result: null,
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.UPLOAD_FILES_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case actionTypes.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        account: action.payload,
      };
    case actionTypes.UPLOAD_FILES_FAILURE:
      return {
        ...state,
        isStarted: false,
        isLoad: true,
        isError: true,
        account: null,
        msg: action.payload,
      };
    case actionTypes.UPLOAD_FILES_CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}
