import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import GoogleAuth from './comGoogleLogin';
import {signinGMBAccount,logoutGMBAccount} from "../../redux/controllers/account.controller";
import {sendMessageToUser} from "../../redux/controllers/listing.controller";

@connect(
    store => ({
        account: store.account.account,
    }),
    {
        signinGMBAccount,
        logoutGMBAccount,
        sendMessageToUser,
    },
)
class GAuth extends Component {
    static propTypes = {
        signinGMBAccount: PropTypes.func.isRequired,
        // logoutGMBAccount: PropTypes.func.isRequired,
        sendMessageToUser: PropTypes.func.isRequired,
        account: PropTypes.shape().isRequired,
    }

    componentDidMount() {
        // const clientId = "769076857869-4nuqgs328i3eqpjbkovi3pubpsr3ufdl.apps.googleusercontent.com" - websitter api;
        // const clientId = "289404544914-rscptk48t20dq6obagc9i5c48ef6854s.apps.googleusercontent.com" - test project;
        const clientId = "953692726732-l6h5u0ftpmjd3ujks0kgmhnhmtpout2a.apps.googleusercontent.com";
        const self = this;
        GoogleAuth.load({
            client_id: clientId,
        }).then(() => {
            self.refreshUserAuth(5000);
            if (GoogleAuth.isSignedIn()) {
                this.logoutButton = true;
                console.info('looks like oauth is done');

                this.onCurrentUser(GoogleAuth.currentUser());
            } else {
                this.userSignedStatus(false);

                // OneTap
                // if (typeof google !== 'undefined' && this.logoutButton === false) {
                //     console.info('loading script');
                //     // eslint-disable-next-line no-undef
                //     google.accounts.id.initialize({
                //         client_id: clientId,
                //         scope: 'profile email https://www.googleapis.com/auth/business.manage',
                //         // fetch_basic_profile: true,
                //         callback: this.handleOneTap,
                //         cancel_on_tap_outside: false,
                //     });
                //     // eslint-disable-next-line no-undef
                //     google.accounts.id.prompt();
                // }
            }
            // if (this.renderParams && this.logoutButton === false) {
            // if (this.renderParams) {
            //     window.gapi.signin2.render(this.id, this.renderParams);
            // }
            this.disabled = false;
        }).catch((err) => {
            console.error('err', err);
            this.disabled = true;
            this.initError = err.details;
        });
    }

    onSuccess = (googleUser) => {
        console.info(`Logged in as: ${  googleUser.getBasicProfile().getName()}`);

        this.onCurrentUser(googleUser);
    }

    onFailure = (error) => {
        console.info(error);
    }

    onCurrentUser = async (user, isHardReload) => {
        // no need to sync if we have a user in account

        const { gmb } = this.props.account;
        if (!isHardReload && (gmb && gmb.email)) {
            console.info('this.account', this.props.account);
            return;
        }

        // if it's a hard reload - then we are passing auth response already
        const authInfo = user.getAuthResponse(true);
        const gmbAccountId = await GoogleAuth.handleGetAccountID(authInfo.access_token);
        if (gmbAccountId.error) {
            this.props.sendMessageToUser(gmbAccountId.error);
            return;
        }

        if (authInfo && authInfo.access_token) {
            const basicProfile = user.getBasicProfile();
            const profileData = {
                name: basicProfile.getName(),
                image: basicProfile.getImageUrl(),
                email: basicProfile.getEmail(),
                access_token: authInfo.access_token,
                account_id: gmbAccountId,
            };
            this.props.signinGMBAccount(profileData);
        } else {
            console.info('no auth info found!');
        }
    }

    refreshUserAuth = () => {
        const self = this;
        setInterval(async () => {
            const googleUser = GoogleAuth.currentUser();
            const authInfo = googleUser.getAuthResponse(true);
            if (!authInfo) {
                console.info('no google user found!');
                return;
            }
            const now = Date.now();
            const timeDiff = Math.floor((authInfo.expires_at - now) / 1000);
            console.info('user GMB auth expires at...', timeDiff);
            if (timeDiff < 900) {
                await googleUser.reloadAuthResponse();
                self.onCurrentUser(GoogleAuth.currentUser(), true);
                // clearInterval(inter);
            }
        //    every 5 min to check
        }, 5 * 60 * 1000);
    }

    userSignedStatus = (signed) => {
        console.info('google user is signed:', signed);

        // check if needed to remove assotiated account

        // if(this.props.account && this.props.account.gmb) {
        //     console.info('delete user GMB data');
        //     this.props.logoutGMBAccount();
        // }
    }

    // parseJwt = (token) => {
    //     var base64Url = token.split('.')[1];
    //     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //     var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join(''));
    //
    //     return JSON.parse(jsonPayload);
    // }

    handleOneTap = async (data) => {
        // const jwt = parseJwt(data.credential);
        //
        // // const googleUser = GoogleAuth.currentUser();
        // if (!googleUser) {
        //     console.info('no google user found!');
        //     return;
        // }
        console.info('googleUser', data);
        // console.info(`Logged in as: ${  googleUser.getBasicProfile().getName()}`);
        // this.onCurrentUser(GoogleAuth.currentUser());
    }

    id = "google-signin-btn"
    logoutButton = false
    renderParams = {
        'scope': 'profile email https://www.googleapis.com/auth/business.manage',
        'theme': 'dark',
        'onsuccess': this.onSuccess,
        'onfailure': this.onFailure,
    }

    render() {
        return <div/>;
    }
}

export default GAuth;