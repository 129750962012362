import { SET_PRACTICE, SET_PRACTICES_SET_IS_FETCHING } from '../constants/practicesActionsTypes';

const initialState = {
  practice: {},
  isFetching: false,
};

function practice(state = initialState, action) {
  switch (action.type) {
    case SET_PRACTICE:
      return {
        ...state,
        practice: action.data,
      };
    case SET_PRACTICES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default practice;
