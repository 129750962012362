import {
  SET_INSURANCES,
  SET_INSURANCES_QUERY_PARAMS,
  SET_INSURANCES_SET_IS_FETCHING,
} from '../constants/insurancesActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 3,
    page: 1,
    sort: 'created_at,desc',
  },
  isFetching: false,
  total: 0,
};

function insurances(state = initialState, action) {
  switch (action.type) {
    case SET_INSURANCES:
      return {
        ...state,
        list: action.data.insurances,
        params: {
          ...state.params,
          ...action.data.pagination,
        },
        total: action.data.total,
      };
    case SET_INSURANCES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_INSURANCES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default insurances;
