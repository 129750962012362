import {
  SET_SUBSPECIALTIES,
  SET_SUBSPECIALTIES_QUERY_PARAMS,
  SET_SUBSPECIALTIES_SET_IS_FETCHING,
} from '../constants/subspecialtiesActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 10,
    page: 1,
    // search: '',
  },
  isFetching: false,
  total: 0,
};

function subspecialties(state = initialState, action) {
  switch (action.type) {
    case SET_SUBSPECIALTIES:
      return {
        ...state,
        list: action.data.subspecialties,
        params: {
          ...state.params,
          ...action.data.pagination,
        },
        total: action.data.total,
      };
    case SET_SUBSPECIALTIES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case SET_SUBSPECIALTIES_SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    default:
      return state;
  }
}

export default subspecialties;
