import {
  FETCH_DICT_INSURANCES_START,
  FETCH_DICT_INSURANCES_SUCCESS,
  FETCH_DICT_INSURANCES_FAILURE,
  SET_DICT_INSURANCES_QUERY_PARAMS,
  FETCH_DICT_INSURANCE,
  FETCH_DICT_INSURANCE_SUCCESS,
} from '../constants/insurancesActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 3,
    page: 1,
  },
  total: 0,
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
  instance: {},
  isFetching: false,
};

function dictInsurances(state = initialState, action) {
  switch (action.type) {
    case FETCH_DICT_INSURANCES_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case FETCH_DICT_INSURANCES_SUCCESS:
      return {
        ...state,
        list: action.payload.dictInsurances,
        total: action.payload.total,
        isStarted: false,
        isLoad: true,
        isError: false,
      };
    case FETCH_DICT_INSURANCES_FAILURE:
      return {
        ...state,
        list: [],
        isStarted: false,
        isLoad: true,
        isError: true,
        msg: action.payload,
      };
    case SET_DICT_INSURANCES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    case FETCH_DICT_INSURANCE:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case FETCH_DICT_INSURANCE_SUCCESS:
      return {
        ...state,
        instance: action.payload,
      };
    default:
      return state;
  }
}

export default dictInsurances;
