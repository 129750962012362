
import get from "lodash/get";

import * as actions from '../actions/listing.actions';
import {Listings} from '../../DAO_hocs/listingsDAO_HOC';
import {showErrorToast} from '../actions/meta.action';
import {logoutGMBAccount} from "./account.controller";
import GoogleAuth from "../../components/GAuth/comGoogleLogin";

export const catchGMBError = (e, dispatch) => {
  switch (e.response.status) {
    case 409: {
      console.error('sync failed', e.response);
      dispatch(showErrorToast({ message: 'Your Google session has expired' }));
      // dispatch logout
      if (typeof google !== 'undefined') {
        GoogleAuth.signOut().then(() => {
          dispatch(logoutGMBAccount());
        });
      }
      return;
    }
    case 422:
    case 429: {
      console.error('sync failed', e.response);
      dispatch(showErrorToast({ message: e.response.data.message }));
      return;
    }
    default: {
      const message = get(e, 'response.data.message', e.message);
      dispatch(showErrorToast(message));
    }
  }
};

export const loadListings = () => async (dispatch, getState) => {
  dispatch(actions.loadListingsStart(true));
  const state = await getState();
  const scope = [
    'withState',
    'withDoctors',
    'withListings',
    'withLocations',
  ];
  const { params } = state.listings.listings;
  try {
    const { data } = await Listings.getListings({
      params: {
        scope,
        ...params,
      },
    });

    const { listings, page, per_page, count: total } = data.data;

    dispatch(
        actions.setListings({
          listings,
          pagination: { page, per_page, total },
          count: total,
        }),
    );
  } catch (e) {
    console.error(e);
    const message = get(e, 'response.data.message', e.message);
    dispatch(actions.loadListingsFailure());
    dispatch(showErrorToast(message));
  }
};

export function account() {
  return async dispatch => {
    try {
      // const response = await Listings.account(authToken);
      // const { totalCount, listingsCount, gmbCount } = response.data.data || {
      //   totalCount: 0, listingsCount: 0, gmbCount: 0,
      // };

      // dispatch(
      //     actions.setListingsSummary({
      //       summary: {
      //         totalCount, listingsCount, gmbCount,
      //       },
      //     }),
      // );
    } catch (err) {
      console.error(err);
      catchGMBError(err, dispatch);
      // dispatch(showErrorToast({ message: err.message }));
    }
  };
}

export function summary() {
  return async dispatch => {
    try {
      const response = await Listings.summary();
      const { totalCount, listingsCount, gmbCount } = response.data.data || {
        totalCount: 0, listingsCount: 0, gmbCount: 0,
      };

      dispatch(
          actions.setListingsSummary({
            summary: {
              totalCount, listingsCount, gmbCount,
            },
          }),
      );
    } catch (err) {
      console.error(err);
      catchGMBError(err, dispatch);
      // dispatch(showErrorToast({ message: err.message }));
    }
  };
}

export function searchListings(options) {
  return async (dispatch, getState) => {
    dispatch(actions.setIsFetching(true));

    const { params } = getState().listings;
    const query = {
      ...params,
      ...options,
    };

    try {
      const response = await Listings.search({ params: query });
      const { listings, page, per_page, count: total } = response.data.data;

      dispatch(
          actions.setListings({
            listings,
            pagination: { page, per_page, search: query.search },
            total,
          }),
      );
      dispatch(actions.setIsFetching(false));
    } catch (err) {
      console.error(err);
      dispatch(actions.setIsFetching(false));
      dispatch(showErrorToast({ message: err.message }));
    }
  };
}

export const setListingsQueryParams = (newParams, needFetch) => (dispatch, getState) => {
  const { params } = getState().listings.listings;
  const queryParams = { ...params, ...newParams };

  dispatch(actions.setQueryParams(queryParams));

  if (needFetch) {
    dispatch(loadListings());
  }
};

export const syncListingGMB = payload => async (dispatch) => {
  await dispatch(actions.setListingIsLoading(true));

  try {
    const { data } = await Listings.syncListingGMB({payload});
    dispatch(actions.setListingIsLoading(false));

    const filterRejected = data.listings.filter(i => i.status === 'rejected');

    if (filterRejected.length) {
      const errors = filterRejected.map(err => {
        let returnedError;
        const { details, message } = err.reason.error;
        if (!details && !message) {
          return 'unknown error';
        }
        let errorWithDetails= null;
        if(details) {
          errorWithDetails =  details[0].errorDetails.map(e => e.value ? `${e.message}: ${e.value}` : e.message);
        }
        if (message) {
          returnedError = message;
        }
        if(errorWithDetails) {
          returnedError = errorWithDetails
        }
        return returnedError;
      });
      dispatch(showErrorToast({ message: errors.length > 3 ? errors.slice(0, 3) : errors.join('\n') }));
    }

    if (filterRejected.length === data.listings.length) {
      return;
    }

    dispatch(actions.syncListingGMB(data.listings));
    // call this to update number of user listings
    dispatch(summary());
  } catch (e) {
    dispatch(actions.setListingIsLoading(false));

    catchGMBError(e, dispatch);
  }
};


export const sendMessageToUser = (message) => async (dispatch) => {
  dispatch(showErrorToast({ message }));
};

export const getListing = payload => async dispatch => {
  const {
    locationId,
    listingId,
  } = payload;
  if (!locationId && !listingId) return Promise.reject();
  await dispatch(actions.loadListingStart());
  try {
    const response = await Listings.getById({ params: payload});
    if (response) {
      await dispatch(actions.loadListingSuccess(response.data.data));
    }
    return Promise.resolve();
  } catch (err) {
    console.error(err);
    await dispatch(actions.loadListingFailure());
    await dispatch(showErrorToast({ message: err.message }));
    return Promise.reject(err);
  }
};
