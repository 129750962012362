import {
  FETCH_INSURANCE_COMPANIES_START,
  FETCH_INSURANCE_COMPANIES_SUCCESS,
  FETCH_INSURANCE_COMPANIES_FAILURE,
  SET_INSURANCE_COMPANIES_QUERY_PARAMS,
} from '../constants/insuranceCompaniesActionsTypes';

const initialState = {
  list: [],
  params: {
    per_page: 3,
    page: 1,
    scope: ['withInsurance', 'withTenant'],
    sort: 'created_at,desc',
    search: '',
  },
  total: 0,
  isStarted: false,
  isLoad: false,
  isError: false,
  msg: null,
};

function insuranceCompanies(state = initialState, action) {
  switch (action.type) {
    case FETCH_INSURANCE_COMPANIES_START:
      return {
        ...state,
        isStarted: true,
        isLoad: false,
        isError: false,
        msg: null,
      };
    case FETCH_INSURANCE_COMPANIES_SUCCESS:
      return {
        ...state,
        list: action.payload.insuranceCompanies,
        total: action.payload.total,
        isStarted: false,
        isLoad: true,
        isError: false,
      };
    case FETCH_INSURANCE_COMPANIES_FAILURE:
      return {
        ...state,
        list: [],
        isStarted: false,
        isLoad: true,
        isError: true,
        msg: action.payload,
      };
    case SET_INSURANCE_COMPANIES_QUERY_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.params,
        },
      };
    default:
      return state;
  }
}

export default insuranceCompanies;
