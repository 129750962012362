import * as actions from '../actions/rolesList.action';

import { Roles } from '../../DAO_hocs/rolesDAO_HOC';

export const loadRolesList = () => async dispatch => {
  await dispatch(actions.loadRolesListStart());
  try {
    const response = await Roles.getList({
      params: {
        page: 1,
        per_page: 40,
      },
    });
    const { roles } = response.data.data;
    await dispatch(actions.loadRolesListSuccess(roles));
  } catch (e) {
    await dispatch(actions.loadRolesListFailure(e.message));
  }
};

export const clearRolesStore = () => async dispatch => {
  await dispatch(actions.clearRolesListStore());
};
