export const SET_LISTINGS = 'SET_LISTINGS';
export const LOAD_LISTINGS_START = 'LOAD_LISTINGS_START';
export const LOAD_LISTINGS_FAILURE = 'LOAD_LISTINGS_FAILURE';
export const LOAD_LISTINGS_SUCCESS = 'LOAD_LISTINGS_SUCCESS';
export const LOAD_LISTINGS_SUMMARY = 'LOAD_LISTINGS_SUMMARY';
export const SET_LISTINGS_QUERY_PARAMS = 'SET_LISTINGS_QUERY_PARAMS';
export const SET_LISTINGS_SET_IS_FETCHING = 'SET_LISTINGS_SET_IS_FETCHING';

export const SYNC_LISTING_GMB = 'SYNC_LISTING_GMB';
export const SYNC_LISTING_GMB_IN_PROGRESS = 'SYNC_LISTING_GMB_IN_PROGRESS';

export const UPDATE_LISTINGS_FILTERS_PARAMS = 'UPDATE_LISTINGS_FILTERS_PARAMS';
export const UPDATE_LISTINGS_SORT_PARAMS = 'UPDATE_LISTINGS_SORT_PARAMS';
export const CLEAR_LISTINGS_FILTER = 'CLEAR_LISTINGS_FILTER';
export const CLEAR_LISTINGS_STORE = 'CLEAR_LISTINGS_STORE';

export const FETCH_LISTING_START = 'FETCH_LISTING_START';
export const FETCH_LISTING_SUCCESS = 'FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_FAILURE =  'FETCH_LISTING_FAILURE ';
